@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.messageContainer{
  width: 550px;
  height: auto;
  background-color: $color-7;
  border: 1px solid $primary;
  border-radius: $radius;
  padding: 30px;
}

.title {
  @include h3;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;
}

.content{
  @include b2($text-primary);
  line-height: 26px;
  margin-bottom: 40px;
}

.btnWrap {
  max-width: 380px;
  margin: 0 auto;
  button {
    height: 51px;
    font-size: 16px;
  }
}