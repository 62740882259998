@import "src/assets/variables";

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btnsWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;

  span {
    font-size: 12px;
    line-height: 15px;
  }
}
