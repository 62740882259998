@import "src/assets/mixins";

.wrapper {
  display: flex;
  gap: 20px;

  @media (max-width: 690px) {
    flex-wrap: wrap;
  }
}

.input {
  width: 100%;
  order: 2;

  @media (min-width: 691px) {
    max-width: 456px;
  }

  @media (max-width: 690px) {
    order: 3;
  }
}

.btnWrapper {
  margin-top: 30px;
  order: 3;

  @media (min-width: 761px) {
    margin-top: 42px;
  }

  @media (max-width: 690px) {
    order: 2;
  }
}

.iconWrapper {
  order: 1;

  @media (min-width: 691px) {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 690px) {
    flex: 1;
  }

  :global(.feature) {
    display: flex;
    gap: 10px;
    align-items: center;

    & > svg {
      min-width: 24px;
    }
  }
}
