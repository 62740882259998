@import "src/assets/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 12px 24px;
  border-radius: $radius;
  background-color: #f6faff;

  @media (min-width: 1380px) {
    padding: 25px;
  }

  @media (min-width: 761px) {
    background-color: $white;
    gap: 20px;
  }

  @media (max-width: 760px) {
    border: $border-primary;
    box-shadow: $shadow-1;
  }
}

.title {
  @include h3();
}

.label {
  @include h4($text-secondary);
}

.contentWrapper {
  display: flex;
  gap: 16px;

  @media (max-width: 1380px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 761px) {
    gap: 30px;
  }
}

.bedsListWrapper {
  display: grid;
  max-width: 520px;
  gap: 16px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 761px) {
    max-width: 620px;
    gap: 20px 30px;
  }
}

.bedCountLabel {
  @media (max-width: 600px) {
    flex: 1;
  }
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 20px;
    max-width: 456px;
  }
}

.bedItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deleteBtn {
  @media (max-width: 760px) {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
