@import "src/assets/mixins";

.message {
  position: fixed;
  bottom: 60px;
  right: 60px;
  padding: 20px 24px;
  z-index: 1000;
  background-color: $white;
  border-radius: $radius;
  border: $border-primary;
  box-shadow: $shadow-2;
  transform: translateY(40px);
  opacity: 0;
  transition: $transition;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 17px;

  @media (max-width: $bookingListMobileBreakPoint1) {
    right: 50%;
    bottom: 30px;
    max-width: 300px;
    transform: translate(50%, 40px);
  }
}

.statusTitle {
  @include h4($text-primary);
}

.description {
  @include h5();
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.errorBorder {
  border-color: $error;
}

.positiveBorder {
  border-color: $color-12;
}

.iconWrapper {
  height: 40px;
  width: 40px;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.positive {
  background-color: $color-12;
}

.error {
  background-color: $error;
}

.showMessage {
  opacity: 1;
  transform: translateY(0);

  @media (max-width: $bookingListMobileBreakPoint1) {
    transform: translate(50%, 0);
  }
}
