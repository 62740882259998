@import "src/assets/mixins";
@import "src/assets/variables";

.title {
  @include b1($text-primary);
  @media screen and (max-width: 760px){ 
    @include h3mob;
  }
}
.content {
  margin-top: 20px;
  .bold{
    font-weight: 600;
  }
}
.btnWrap {
  max-width: 288px;
  margin-top: 20px;
  button {
    height: 51px;
    font-size: 16px;
  }
}