@import "src/assets/mixins";

.contentTitle {
  margin-bottom: 16px;
  @include h3();

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
}

.settingsWrapper {
  max-width: 942px;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 761px) {
    gap: 30px;
  }
}

.objWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 20px;
  }
}

.description {
  @media (max-width: 761px) {
    height: 314px;
  }
}

.objTopWrapper {
  display: grid;
  gap: 16px;

  @media (min-width: 970px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 761px) {
    gap: 20px 30px;
  }
}

.featuresBtnsWrapper {
  display: flex;
  gap: 20px;
}

.mainBtnsWrapper {
  display: flex;
  gap: 10px;
  margin-top: 30px;

  @media (min-width: 481px) {
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}

.featureOptionWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.featureImgWrapper {
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.maxPlacesWrapper {
  padding-top: 30px;
}

.featuresList {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 20px;
  }
}
