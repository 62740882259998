@import "src/assets/mixins";

.contTabs {
  justify-content: flex-start;
  .setTabs {
    flex: 0 0 325px;
  }
}
.title {
  margin-bottom: 20px;
  @include h3();
  @media screen and (max-width: 760px){
    @include h3mob();
  }
}
.contentContainer {
  margin-top: 20px;
}
.btnContainer{
  @media screen and (max-width: 760px){ 
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 680px){ 
    max-width: 450px;
  }
  @media screen and (max-width: 400px){ 
    display: block;
  }
}