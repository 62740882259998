@import "src/assets/mixins";

.container {
  position: relative;
}

.wrapper {
  display: flex;
  gap: 10px;
  height: 50px;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.username {
  @include b3($text-primary);
  margin-bottom: 4px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobTitle {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: $text-secondary;
}

.arrowBtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: rotate(-90deg);
}

.dropdownMenu {
  position: absolute;
  border-radius: $radius;
  box-shadow: $shadow-2;
  width: 100%;
  left: 0;
  top: 60px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.button {
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid $color-5;

  &:last-child {
    border-bottom: 0;
  }
}
