@import "src/assets/mixins";

.objectName {
  @include h3();
}

.wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
}
