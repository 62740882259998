@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "src/assets/variables";

:root {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $text-primary;
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
}

* {
  box-sizing: border-box;
}
