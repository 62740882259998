@import "src/assets/mixins";

.wrapper {
  border-radius: $radius;
  box-shadow: $shadow-2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: $white;
  gap: 20px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
}

.title {
  @include h4($text-secondary);
  margin-bottom: 10px;
}

.inputWrapper {
  display: flex;
  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
  }
}

.cardItem {
  @media (min-width: 1101px) {
    width: 230px;
  }

  @media (max-width: 1100px) {
    flex: 1;
  }

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.calendar {
  border: $border-secondary;
  box-shadow: none;

  @media (min-width: 1101px) {
    width: 230px;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
  .customIcon{
    right: 18px;
  }
  svg {
    width: 22px;
    height: 22px;  
  }
  
}

.controls {
  display: flex;
  gap: 10px;
  align-self: center;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    align-self: flex-end;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
}

.cancelBtnWrap {
  @media (max-width: 360px) {
    order: 1;
    flex: 1;
  }
}

.saveBtnWrap {
  flex: 1;
}

.saveBtn {
  width: 100%;
}

.cancelBtn {
  @media (max-width: 360px) {
    width: 100%;
  }
}

.viewTitle {
  @include b2($text-secondary);
  margin-bottom: 10px;
}

.errorText {
  @include h5($text-error);
  margin: 5px 0 0 20px;
}

.errorInput {
  border-color: $error;
}

.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  left: 0;
  top: 0;
  border-radius: $radius;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
