@import "src/assets/variables";

.wrapper {
  height: 110px;
  display: flex;
  padding: 28px 20px 15px;
  justify-content: space-between;
  @media screen and (max-width: $mainMobileBreakPoint) {  
    justify-content: center;    
    height: auto;
    padding: 10px 16px 0px 16px;
    // @media screen and (orientation:portrait){
    //   padding: 16px 16px 0px 16px;
    // }
    // @media screen and (orientation:landscape){
    //   padding: 6px 16px 0px 16px;
    // }
  }

}

.titleWrapper {
  h1 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    margin-bottom: 10px;
    @media screen and (max-width: $mainMobileBreakPoint){  
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 2px;
      // @media screen and (orientation:landscape){
      //   line-height: 22px;
       
      // }
    }
    @media screen and (max-width: 360px){  
      font-size: 18px;
      line-height: 18px;
    }
  }
  p {
    @media screen and (max-width: $mainMobileBreakPoint){  
      display: none;
      // font-size: 18px;
      // text-align: center;
    }
  }
}

.rightControlsWrapper {
  height: 52px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $mainMobileBreakPoint){  
    display: none;
  }
}

.searchWrapper {
  position: relative;
  width: 304px;

  .search {
    box-shadow: $shadow-2;
    border: 0;
    padding: 0 50px 0 20px;
    font-size: 14px;
    line-height: 16px;
    height: 51px;

    &:focus {
      border: 0;
    }
  }
  
}

.searchBtn {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.headerControls {
  display: flex;
  gap: 12px;
  margin: 0 16px 0 48px;
  @media screen and (max-width: 1200px){   
    margin-left: 16px;
  }
}

.headerBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
