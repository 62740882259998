@import "src/assets/mixins.scss";

.container {
  width: 100%;
  height: 1705px;
  // display: flex;
  // justify-content: flex-start;
}
.setTabs {  
  flex: 0 0 325px;
  @media screen and (max-width: 1200px){ 
    flex: 33;
    align-items: center;
  }
}
.contTabs{
  justify-content: flex-start;
}