@import "src/assets/mixins";

.tabsWrapper {
  display: flex;
  height: 67px;
  @media screen and (max-width: 760px){ 
    height: 40px;
  }
  &.wrapBooking {
    height: 33px;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 67px;
  transition: $transition;
  border: $border-secondary;
  border-left: 0;
  @include b3($text-secondary);
  background-color:$white;

  &.big{
    width: 189px;
  }
  &.booking {
    width: 88px;
    height: 33px;
  }
  &.price {
    width: 140px;
    height: 52px;
    @media screen and (max-width: 760px){ 
      height: 40px;
    }
    @media screen and (max-width: 520px){       
      width: 100px;
      font-size: 12px;
    }
  }
  &:active {
    background-color: $color-8;
  }

  &:first-child {
    border-radius: $radius 0 0 $radius;
    border: $border-secondary;
  }

  &:last-child {
    border-radius: 0 $radius $radius 0;
  }
  @media screen and (max-width: 380px){ 
    font-size: 12px;
  }
}

.activeTab {
  background-color: $primary;
  border: 0 !important;
  color: $text-white;  
}

.notActiveTab {
  color: $text-primary;
  border-color: $primary !important;
  
}
.disabled{
  height: 33px;
  width: 88px;
  border-radius: 6px;
  border: $border-small;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-content: center;
  flex-wrap: wrap;
  @include h5;
  font-weight: 500;
  cursor: not-allowed;
  color: $color-4;
}