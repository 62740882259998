@import "src/assets/mixins.scss";

.title {
  @include h4();
  font-weight: 500;

  span {
    font-weight: 400;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;  
}

.btnFormWrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
  @media screen and (max-width: 680px){ 
    justify-content: flex-start;
  }
  @media screen and (max-width: 470px){ 
    flex-direction: column;
    button, div, a{
      width: 100%;
    }
  }
}
.columnGrid{
  display: grid;
  grid-template-columns: 456px 456px;
  grid-template-rows: repeat(4, auto);
  grid-gap: 20px 120px;
  grid-auto-flow: column;
  align-items: end;
  @media screen and (max-width: 1260px){ 
    grid-gap: 20px 60px;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 770px){ 
    grid-gap: 20px 25px;
  }
  @media screen and (max-width: 680px){    
    grid-template-columns: 450px ;
    grid-auto-flow: row;
    
  }
  @media screen and (max-width: 470px){
    grid-template-columns: 1fr ;
  }

}

.columnAdd {
  // width: 456px;
  // display: flex;
  // flex-direction: column;
  // gap: 20px;
  // @media screen and (max-width: 1260px){ 
  //   flex: 50%;
  //   width: auto;   
  // }
  display: grid;
  grid-template-columns: 456px 456px;
  grid-template-rows: repeat(2, auto);
  grid-gap: 20px 120px;
  grid-auto-flow:column;
  grid-template-areas: "a b"
                       "a c";
  
  @media screen and (max-width: 1260px){ 
    grid-gap: 20px 60px;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 770px){ 
    grid-gap: 20px 25px;
  }
  @media screen and (max-width: 680px){    
    grid-template-columns: 450px ;
    grid-template-areas: "b"
                         "c";
    grid-auto-flow: row;
    
  }
  @media screen and (max-width: 470px){
    grid-template-columns: 1fr ;
  }
  
}

.checkBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px; 
  
}
.askBox{
  grid-area: b;
}
.column{
  grid-area: c;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.postAddressError {
  @include h5($text-error);
  margin-top: 5px;
}

.postInputsError {
  input {
    border: $border-error;
  }
}
