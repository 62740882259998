@import "src/assets/variables";

@mixin button($color: $text-white) {
  cursor: pointer;
  border-radius: $radius;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  transition: $transition;

  @include b1($color);

  @media (max-width: $mainMobileBreakPoint) {
    height: 40px;
    @include b1mob($color);
  }
}

@mixin buttonSmall($color: $text-white) {
  cursor: pointer;
  border-radius: $radius;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  padding: 5px 20px;
  transition: $transition;
  text-align: center;
  @include b2($color);

  @media (max-width: $mainMobileBreakPoint) {
    height: 40px;
    font-size: 14px;
  }
}

@mixin typography($fw, $fs, $lh, $color) {
  font-family: "Montserrat", sans-serif;
  font-weight: $fw;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}

@mixin h1($color: $text-primary) {
  @include typography(600, 40px, 49px, $color);
}

@mixin h2($color: $text-primary) {
  @include typography(600, 30px, 1.2em, $color);
}

@mixin h2mob($color: $text-primary) {
  @include typography(600, 20px, 24.38px, $color);
}

@mixin h3($color: $text-primary) {
  @include typography(500, 20px, 25px, $color);
}

@mixin h3mob($color: $text-primary) {
  @include typography(500, 18px, 22px, $color);
}

@mixin h4($color: $text-primary) {
  @include typography(400, 16px, 20px, $color);
}

@mixin h5($color: $text-primary) {
  @include typography(300, 14px, 17px, $color);
}

@mixin b1($color: $text-white) {
  @include typography(500, 20px, 1.2, $color);
}

@mixin b1mob($color: $text-white) {
  @include typography(600, 16px, 1.2, $color);
}

@mixin b2($color: $text-white) {
  @include typography(500, 16px, 1.2, $color);
}

@mixin b3($color: $text-white) {
  @include typography(500, 14px, 1.2, $color);
}
@mixin reg12mobSemi($color: $text-primary) {
  @include typography(500, 12px, 14px, $color);
}

@mixin h4mob($color: $color-4) {
  @include typography(400, 14px, 20px, $color);
}

@mixin placeholder($color: $text-secondary) {
  @include typography(400, 14px, 17px, $color);
}
