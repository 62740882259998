@import "src/assets/mixins.scss";
@import "src/assets/variables";

.contTabs {
  justify-content: flex-start;
  .setTabs {
    flex: 0 0 325px;
  }
}

.title {
  @include h4();
  font-weight: 500;
}

.form {
  display: grid;
  grid-template-columns: 456px 456px;
  grid-template-rows: repeat(7, auto);
  grid-gap: 20px 120px;
  grid-auto-flow: column;
  @media screen and (max-width: 1260px){ 
    grid-gap: 20px 60px;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 770px){ 
    grid-gap: 20px 25px;
  }
  @media screen and (max-width: 680px){    
    grid-template-columns: 450px ;
    grid-auto-flow: row;
    
  }
  @media screen and (max-width: 470px){
    grid-template-columns: 1fr ;
  }
}
.country{
  z-index: 2;
}
.btnFormWrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
  @media screen and (max-width: 680px){ 
    justify-content: flex-start;
  }
  @media screen and (max-width: 470px){ 
    flex-direction: column;
    button, div, a{
      width: 100%;
    }
  }
}
