@import "src/assets/mixins";
@import "src/assets/variables";

.wrap{
  @include b3($color-4);
  padding: 0px 13px 21px 13px;
  width: 300px;
  height: 51px;
  border-radius: $radius;
  border: 1px solid $color-4;
  z-index: 5;
  background-color: $white;
  // position: absolute;  
  &.open {
    height: auto;    
  }
  &.active{
    border-color: $primary;
    box-shadow: $shadow-1;
    
  }
  @media screen and (max-width: $mainMobileBreakPoint){
    @include h4mob;  
    width: 288px;
    height: 40px;
  }
}

.input{
  width: 274px;
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;  
  cursor: pointer;
  position: relative;
  @media screen and (max-width: $mainMobileBreakPoint){
    width: 262px;
    height: 38px;
  }
}

.text{
  @include b3($color-4);
  width: 250px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;  
  @media screen and (max-width: $mainMobileBreakPoint){
    @include h4mob;  
  } 
}
.active{
  .text{
    color: $text-primary;
  }
}

.icon{
  padding-top: 2px;
  display: flex;
  align-self: center; 
}

.open{
  .input{
    border-color: none;
  }
  .icon {
    transform: rotate(180deg);
  }
}

.divider{
  border: 0.5px solid $primary;
  margin-bottom: 10px;
}
.optionsList{
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-color: $primary $color-26;
  scrollbar-width: thin; 
}
.optionLine{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  &:last-of-type{
    margin-bottom: 0px;
  }
}