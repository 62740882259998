@import "src/assets/mixins";
@import "src/assets/variables";

.window {
  width: 750px;
  border: 1px solid $primary;
  @media screen and (max-width:$bookingFormBreakPoint1){
    width: 590px;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    width: 288px;
  }
}
