@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  height: 25px;
  background-color: $color-35;
  padding: 5px 12px 5px 12px;
  border-radius: $radius;
  display: flex;
  gap:10px;
  align-items: center;

  .btnClose {
    cursor: pointer;
    svg{ 
      width: 10px;
      height: 10px;
      path {
          fill:$primary;
          opacity: 55%;
      }
  }
  }
}