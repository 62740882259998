@import "src/assets/mixins";
@import "src/assets/variables";

.note {
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: justify;
  @include h4 ($text-primary);
  font-weight: 400;
  line-height: 28px;
  .stroke{
    text-indent: 25px;
  }
  .bold{
    font-weight: 600;
  }
  @media screen and (max-width: 540px), (orientation:landscape ) and (max-height:400px){
    font-size: 14px;
  }

}

.btnGroup {
  @include b2 ($text-primary);
  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    input {
      margin: auto 0;
      height: 20px;
      width: 20px;
      flex-shrink: 0;
    }
    @media screen and (orientation:landscape ) and (max-height:400px){
      margin-bottom: 10px;
    }
  }
}
.window {
  border-color: $error;
  
}
.btnWrap {
  display: flex;
  justify-content: center;
  button {
    height: 51px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}