@import "src/assets/mixins";
@import "src/assets/variables";

.container {
    @include h3;

    height: 80px;
    background-color: $color-8;
    color: $color-7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: static;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    @media screen and (max-width:$bookingFormBreakPoint2){
        height: 52px;
        padding: 12px;
    }
    
}

.btnPanel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;  
    button {  
     height: 37px;
     @include b3;
    }
    .btnClose {
        padding-left: 20px;
        cursor: pointer;
        svg path {
            fill:$color-7;
        }
        @media screen and (max-width:$bookingFormBreakPoint2){
            padding: 0px;
            display: flex;
            align-items: center;
        }
    }
    .btnSave{
        @media screen and (max-width:$bookingFormBreakPoint2){
            display: none;
        }
    }
}
.title{
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    align-items: baseline;
    @media screen and (max-width:$bookingFormBreakPoint2){
        gap: 0px;
    }
    .titleAdd {
        @include h4;
        font-size: 12px;
        color: $color-7;
        // padding-left: 6px;
    }
}
.btnDel{
    position: relative;
    button{
        background-color: $white;
        color:$text-primary;

    }
    &.btnActiveDel{
        button{
        background-color: $error;
        color:$white;
        
        }
    }
    .help {
        display: none;
        position: absolute;
        background-color: $color-4;
        border-radius: 6px;
        box-shadow: $shadow-2;
        @include h5;
        font-weight: 400;
        color: $white;
      
      }
    &.mob{
        svg > path {
            fill: $color-6;            
          }
    }  
    &:hover{        
     & .help {
        display: block;
        width: 280px;
        top:42px;
        text-wrap: wrap;
        padding: 4px 8px 4px 8px;
        z-index: 5;
        right: -70px;
        @media screen and (max-width:$bookingFormBreakPoint2){
            width: 200px;
            right: -40px;
        }
    }

    } 
    
}
.desktop{
    @media screen and (max-width:$bookingFormBreakPoint2){
        display: none;
    }
}
.mob{
display: none;
@media screen and (max-width:$bookingFormBreakPoint2){
    display: block;
}
}    