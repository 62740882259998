@import "src/assets/mixins";

.checkbox {
  display: flex;
  gap: 15px;
  position: relative;

  @media (max-width: 480px) {
    gap: 10px;
  }

  input {
    position: absolute;
    z-index: 5;
    width: 21px;
    height: 21px;
    left: -2px;
    top: -1px;
    cursor: pointer;
    opacity: 0;

    &:checked ~ .customCheckbox {
      background-color: $primary;
    }
  }
  &.multipleselect {
    align-items: center;
    .label {
      @include b3($text-secondary);
    }
  }
}

.customCheckbox {
  margin: 2px;
  height: 21px;
  width: 21px;
  min-width: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $border-primary;
  border-radius: $radius;
  cursor: pointer;
}

.label {
  @include h4($text-secondary);

  @media (max-width: 480px) {
    font-size: 14px;
  }
}
