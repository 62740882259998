@import "src/assets/mixins";
@import "src/assets/variables";


.title {
  @include h4($text-primary);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $bookingFormBreakPoint1){
    font-weight: 600;
  }
  @media screen and (max-width: $bookingFormBreakPoint2){
    flex-direction: column-reverse;
    gap: 10px;
  }
}
.divider {
  width: 100%;
  height: 2px;
  background-color: $primary;
  opacity: 50%;
}
.created {
  @include h4mob;
}