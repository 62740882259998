@import "src/assets/mixins";
@import "src/assets/variables";

.wrap{
  position: relative;
  margin-top: 10px;
}
.mainWindow{
  height: 33px;
  padding: 0px 10px 0px 10px;
  border: $border-small;
  border-radius: $radius;
  background-color: $white;
  @include h5;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.active {
    border: $border-primary;
  }
}
.arrow{
  padding-top: 2px;
  display: flex;
  align-self: center;
  cursor: pointer;
}
.open{
  .arrow {
    transform: rotate(180deg);
  }
}

.addWindow{
  position: absolute;
  background-color: $white;
  padding: 10px 14px 10px 14px;
  border-radius: $radius;
  width: inherit;
  @include h5;
  font-weight: 400;
  top: 59px;
  border: $border-primary;
  box-shadow: $shadow-tooltip;
  color: $color-4;
  z-index: 10;
  width: 345px;
  button {  
    height: 37px;
    @include b3;
   }
   @media screen and (max-width:$bookingFormBreakPoint1){
    width: 265px;
    padding: 8px 11px 8px 11px;
  }
}
.countLine{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.counter {
  width: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sign {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btnLine{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.select{
  margin-bottom: 5px;
}
.view{
  cursor: not-allowed;
  color: $color-4;
}