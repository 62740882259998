@import "src/assets/mixins";
@import "src/assets/variables";

.container {
  display: grid;
  grid-template-columns: 1fr 800px;
  justify-content: stretch;
  min-height: 80px;
  align-items: center;  
  border-bottom: 0.25px solid $color-13;
  padding: 24px 0px;
  .subTitle {
    @include h4;
    padding-left: 20px;
  }
  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr 576px;
  }
  @media screen and (max-width: 1048px) {
    grid-template-columns: 1fr 520px;    
  }
  @media screen and (max-width: $mainMobileBreakPoint) {
    display: block;   
    border: none;
    border-bottom: 1px solid $color-6-1;
    padding: 0px 0px 8px 0px;
    min-height: 0px;
    .subTitle {
      @include h4mob($text-primary);
      padding:16px 0px 8px 0px;
      text-align: center;
    }
  }
}
.signWrap{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;  
}
.sign{
  text-align: center;
  svg{
    width: 24px;
    height: 24px;
    @media screen and (max-width: 600px) {
      width: 16px;
      height: 16px;
    }
  }
}