@import "src/assets/mixins";

.text {
  @include h4($text-secondary);
  margin-bottom: 30px;

  @media (max-width: 760px) {
    margin-bottom: 16px;
  }
}

.btnsWrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;

  @media (max-width: 760px) {
    gap: 8px;
  }

  span {
    font-size: 12px;
    line-height: 15px;
  }
}

.window {
  min-height: 375px;

  @media (min-width: 1300px) {
    left: 320px;
  }
}
