@import "src/assets/mixins";

.customSelect {

  // caret-color: transparent;
  &__title {
    @include h4($text-secondary);
    margin-bottom: 10px;
    &.small {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    position: relative;
    // z-index: 2;
  }
  &-small {
    .customSelect__control {
      padding-inline: 10px;
    }
  }
  &__errText {
    @include h5($text-error);
    margin: 5px 0 0 20px;

    @media (max-width: $mainMobileBreakPoint) {
      margin-left: 8px;
    }

    &.small {
      font-size: 12px;
      font-weight: 400;
      margin: 2px 0 0 10px;
    }
  }

  &__control {
    padding-inline: 20px;
    height: 67px;
    border-radius: $radius;
    width: 100%;
    border: $border-secondary;
    cursor: text !important;

    @media (max-width: $mainMobileBreakPoint) {
      height: 40px;
      padding-inline: 8px;
    }

    @include b3($text-primary);
  }

  &__indicators {
    gap: 3px;
  }

  &__indicator {
    cursor: pointer !important;
    // position: absolute;

    &:hover > svg > path {
      stroke: $color-6 !important;
    }
    > svg > path {
      stroke: $color-4 !important;
    }
  }

  &__placeholder {
    @include placeholder();
  }

  &__menu {
    border-radius: 0 0 $radius $radius;
    padding: 0;
    border: $border-primary;
    border-top: 0;
    box-shadow: $shadow-1;
    background-color: $white !important;
    padding: 15px 20px 20px 20px;
    transform: translateY(-15px);
    @media (max-width: $mainMobileBreakPoint) {
      padding: 10px 8px 8px 8px;
      transform: translateY(-5px);
    }
    
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      background-color: $primary;
      width: calc(100% - 40px);
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }

    &-list {
      &::-webkit-scrollbar {
        width: 24px;

        @media (max-width: $mainMobileBreakPoint) {
          width: 12px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 14px;
        border: 9px solid $white;

        @media (max-width: $mainMobileBreakPoint) {
          border-width: 2px;
        }
      }
    }
  }

  &__option {
    cursor: pointer !important;
    overflow: hidden;
    @include b3();
    margin-bottom: 12px;

    @media (max-width: $mainMobileBreakPoint) {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &:hover {
      color: $color-8 !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-disabled {
    cursor: not-allowed;

    &.customSelect-small {
      border: $color-31;

      .customSelect__indicators {
        display: none;
      }
      .customSelect__control {
        padding: 0 10px;
        color: $color-4;
      }
    }
  }

  // &__noOptionsMessage {
  //   background-color: black;
  // }
}
.customSelect-small{
  .customSelect__menu {
  transform: translateY(-4px);
  }
}
