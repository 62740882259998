@import "src/assets/mixins";

.checkWrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 410px) {
    flex-direction: column;
    gap: 16px;
  }
}

.checkInput {
  @media (min-width: 411px) {
    max-width: 280px;
    width: 100%;
  }
}

.minBookingDays {
  display: flex;
  gap: 16px;

  @media (max-width: 410px) {
    flex-direction: column;
  }
}

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
}

.daysCountLabel {
  @media (max-width: 410px) {
    flex: 1;
  }
}

.mainBtnsWrapper {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  flex: 1;
  justify-content: flex-end;

  @media (min-width: 481px) {
    gap: 20px;
    align-items: flex-end;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}
