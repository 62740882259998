@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
  padding-bottom: 25px;
  position: relative;
  top: -10px;  
}
.blockWrap {
  box-shadow: $shadow-2;
  border-radius: $radius;
  border: $border-primary;
  background-color: $color-7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;  
  padding-top: 3px;
  @media screen and (max-width: $mainMobileBreakPoint) {
    border: none;
    box-shadow: none;
    background: none;   
  }
  button {
    
    height: 52px;  
    @media screen and (max-width: 760px) {
      height: 40px;
    }
  }
}
.radioBtnWrap{
  padding-top: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;
  background-color: $color-7;
  @media screen and (max-width: 1473px) {
    position: sticky;
    top: -12px;   
  }
  @media screen and (max-width: $mainMobileBreakPoint) {
    position: sticky;
    top: 44px;
    background-color: $white;
    padding-top: 8px;
    padding-bottom: 5px;
  }
}
.cardsWrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;  
  padding: 0px 20px 20px 20px;
  width: calc(100%);  
  @media screen and (max-width: 760px) {
    padding: 10px 0px ;
  }
}
.abilityWrap{
  min-width: 1298px;
  width: 100%;
  border-radius: $radius;
  border: 1px solid $color-13;
  padding: 20px 20px;
  box-shadow: $shadow-2;
  @media screen and (max-width: 1440px) {
    width: 100%;
    min-width: auto;
  }
  @media screen and (max-width: $mainMobileBreakPoint) {
    border: none;
  }
  @media screen and (max-width: 540px) {
    padding: 5px;
  }
}
.toggleTitle{
  @include h3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
    @media screen and (max-width: 600px) {
    @include b2($text-primary);
  }
  @media screen and (max-width: 400px) {
    @include b3($text-primary);
    gap: 4px;
  }
}
.arrow{
  cursor: pointer;
  transform: rotate(0deg) translateY(4px);
  transform-origin: center;
  transition: all 0.1s;
  &.arrowOpen{
    transform: rotate(180deg) translateY(3px);    
  }
  svg {
    width: 28px;
    @media screen and (max-width: 400px) {
      width: 20px;
    }
  }
}
.subtitle{
  font-size: 12px;
  margin-top: 4px;
  color: $primary;
   span{
    font-weight: 700;
   }
  &.active{
    color: $white;
  } 
  @media screen and (max-width: 760px) {
    font-size: 11px;
    margin-top: 2px;
    font-weight: 400;
  }
}
.link{
  @include h4;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
  line-height: 23px;
  @media screen and (max-width: 760px) {
    margin-top: 10px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}
.possContainer{
  margin-top: 15px;
  @media screen and (max-width: 500px) {
    margin-top: 5px;
  }
}