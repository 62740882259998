@import "src/assets/mixins";
@import "src/assets/variables";

.container {
  @include h4;
  font-weight: 500;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap:20px;
  height: 71px;
  position:absolute;
  z-index: 3;  
  cursor: pointer;
  // &:hover{
  //   z-index: 5;
  // }
  @media screen and (max-width:$mainMobileBreakPoint){
    height: 48px;
    border-radius: $radius;
    padding: 1px 6px;
    align-items: center;
  }
}

.last{
  background-color: $color-18;
  color: $color-4;
  .tt_icon {
    svg path{
      stroke:$color-4;
    }
  }
  .flag {
    opacity: 35%;
  }
}
.await{
  background-color: $color-19;
  color: $error;
  .tt_icon {
    svg path{
      stroke:$error;
    }
  }
}
.full_paid{
  background-color: $color-20;
  color: $color-21;
  .tt_icon {
    svg path{
      stroke:$color-21;
    }
  }
}
.part_paid {
  background-color: $color-22;
  color:$color-23;
  .tt_icon {
    svg path{
      stroke:$color-23;
    }
  }
}
.not_paid {
  background-color: $color-24;
  color:$color-25;
  .tt_icon {
    svg path{
      stroke:$color-25;
    }
  }
}


.full_paid, .part_paid {
  .rigth{
   min-width: 75px;
 
  }
}

.tooltip{
  display: block;
  padding: 10px 16px 10px 16px;
  @media screen and (max-width:$mainMobileBreakPoint){
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tt_name{
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  @media screen and (max-width:$mainMobileBreakPoint){
    display: none;
  }
}
.tt_full {
  display: none;
  position: absolute;
  top:-31px;
  z-index: 3;
}
.tt_icon{
  text-align: center;
  margin-top: 10px;
  opacity: 30%;
  &:hover{
    opacity: 100%;  
     
  }
  &:hover ~.tt_full{
    display: block;
    @media screen and (max-width:$mainMobileBreakPoint){
      display: none;
    } 
  }
  &:hover ~.container{
    z-index: 4 !important;
    @media screen and (max-width:$mainMobileBreakPoint){
      display: none;
    } 
  }
  @media screen and (max-width:$mainMobileBreakPoint){
    opacity: 50%;
    margin: 0px;
    height: 25px;
      svg{
        width: 25px;
        height: 25px;            
      }
    }
  }

