@import "src/assets/mixins.scss";

.title {
  @include h3;
  margin-bottom: 20px;
}
.form {
  display: flex;
  justify-content: left;
  gap: 120px;
  @media screen and (max-width: 1048px) {
    gap: 60px;
    flex: 246px 1fr;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    gap: 20px;
  }
}
.leftColumn {
  width: 456px;
  @media screen and (max-width: 1048px) {
    width: 100%;
    max-width: 456px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.imageContainer {
  max-width: 456px;

  .title {
    margin-bottom: 25px;
  }

  .imgWrapper {
    aspect-ratio: 1;
    width: 200px;
    margin-inline: auto;
  }
}
.errorText {
  position: relative;
  @include h5($text-error);
  margin-top: 30px;
}
.btnFormWrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
  @media screen and (max-width: 640px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 470px) {
    flex-direction: column;
    button,
    div,
    a {
      width: 100%;
    }
  }
}
