@import "src/assets/mixins";
@import "src/assets/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @include b3;  
  .column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .linkItem{
    display: flex;
    gap: 1px;
    text-decoration: none;
    align-items: center;
    color: $color-37;
    &:visited{
      text-decoration: none;
      color: $color-37;
    }
    span{
      text-wrap: nowrap;
    }
  }
  .icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.desktop{    
    gap: 16px;
    font-size: 16px;
    @media screen and (max-height:600px) and (orientation:landscape){
      gap: 0px;
    }
    .column {
      gap: 16px;
      @media screen and (max-height:600px) and (orientation:landscape){
        gap: 0px;
      }
    }
    .linkItem{
      gap: 5px;
      
    }  
    .icon {
      width: 32px;
      height: 32px;
      // svg {
      //   width: 32px;
      //   height: 32px;
      // }
    }  
  }  
}




