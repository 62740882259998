@import "./../../assets/variables.scss";
@import "./../../assets/mixins.scss";

.myCalendar {
  width: 300px;
  border: none;
  border-radius: 16px;
  box-shadow: $shadow-1;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  z-index: 6;
  // overflow: hidden;
  padding: 8px;
  background-color: $white;
  @media screen and (max-width: $mainMobileBreakPoint) {
    padding: 4px;
    width: 266px;
    font-size: 14px;
  }
  :global .react-datepicker__month-container {
    float: left;
    width: 284px;
    background-color: $white;
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 258px;
    }
  }
  :global .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 284px;
    // height: 38px;
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 258px;
      height: 38px;
    }
  }
  :global .react-datepicker__day-name {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 38px;
      height: 35px;
    }
  }
  :global .react-datepicker__day-names {
    width: 284px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 258px;
      height: 35px;
    }
  }
  :global .react-datepicker__day {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &:hover {
      border-radius: 50%;
      background-color: $color-17;
    }
    &.customWeekEnd {
      color: $color-14;
      &.react-datepicker__day--disabled {
        opacity: 40%;
      }
    }
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 38px;
      height: 38px;
    }
  }
  :global .react-datepicker__current-month {
    text-transform: capitalize;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    // margin-bottom: 3px;
    @media screen and (max-width: $mainMobileBreakPoint) {
      font-size: 16px;
      padding-bottom: 0px;
    }
  }
}
:global .react-datepicker-popper.myPopper {
  z-index: 50;
}
.dateInput {
  @include b3;
  width: 356px;
  height: 67px;
  padding: 21px 20px 21px 20px;
  border-radius: 6px;
  outline: none;
  border: none;
  background-color: $white;
  color: $text-primary;
  box-shadow: $shadow-1;
  cursor: pointer;
  caret-color: transparent;
  
  @media screen and (max-width: $mainMobileBreakPoint) {
    width: 288px;
    height: 40px;
    
    padding: 8px 2px 8px 10px;
    box-shadow: none;
    border: $border-small;
    
    background-color: $white;
  }
  &.empty{    
    background: url("./../../assets/icons/CalendarIcon.svg") no-repeat right 16px center ;
    background-color: $white;
    background-size: 20px;
    @media screen and (max-width: $mainMobileBreakPoint) {
      background: url("./../../assets/icons/CalendarGrey.svg") no-repeat right 18px center;
      background-color: $white;
    }
  }
  
  &.small {
    height: 33px;
    height: 33px;
    background-size: 18px;
    padding: 8px 2px 8px 10px;
    box-shadow: none;
    border: $border-small;
    width: 346px;
    @media screen and (max-width: $bookingFormBreakPoint1) {
      width: 264px;
    }
    &.dis {
      background-image: none;
      width: 100px;
      cursor: not-allowed;
      color: $color-4;
    }
    &.error {
      border: $border-error;
    }
  }
  &.bookingList {
    width: 300px;
    height: 50px;
    border: 1px solid $primary;
    padding: 5px 13px 5px 13px;
    background-image: none;
    background-color: $white;
    &.empty {
      box-shadow: none;
      border-color: $color-4;
      background: url("./../../assets/icons/CalendarGrey.svg") no-repeat right 14px center;
      background-color: $white;
      background-size: 24px;
    }
    @media screen and (max-width: $mainMobileBreakPoint) {
      width: 288px;
      height: 40px;
    }
  }
}
.Calendar_dateInput--disabled {
  cursor: not-allowed;
}
.icon {
  position: relative;
  left: -30px;
}

:global .react-datepicker__tab-loop {
  z-index: 50;
  position: relative;
}
:global .react-datepicker__header {
  text-align: center;
  background-color: white;
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  position: relative;
  width: inherit;
  overflow: hidden;
}

:global .react-datepicker__day--selected,
:global .react-datepicker__day--in-selecting-range,
:global .react-datepicker__day--in-range {
  border-radius: 50%;
  background-color: $color-8;
  color: $color-7;

  &:hover {
    background-color: $color-8;
    border-radius: 50%;
  }
}
:global .react-datepicker__navigation {
  top: 12px;
  @media screen and (max-width: $mainMobileBreakPoint) {
    top: 3px;
  }
}

:global .react-datepicker__month {
  margin: 0;
  padding-top: 3px;
  background-color: $white;
}
:global .react-datepicker__day--today {
  background-color: $color-15;
  border-radius: 50%;
  color: #000;
}
:global .react-datepicker__navigation--previous {
  left: 12px;
}
:global .react-datepicker__navigation--next {
  right: 12px;
}

:global .react-datepicker__year-dropdown-container--select,
:global .react-datepicker__month-dropdown-container--select,
:global .react-datepicker__month-year-dropdown-container--select,
:global .react-datepicker__year-dropdown-container--scroll,
:global .react-datepicker__month-dropdown-container--scroll,
:global .react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
  height: 30px;
}
:global
  .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select
  select,
:global
  .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select
  select {
  height: 30px;
  border-radius: $radius;
  padding: 3px 6px 3px 10px;
  border: 1px solid $color-4;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  &:focus-visible {
    outline: none;
  }
}
:global
  .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select
  option,
:global
  .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select
  option {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: $color-4;
  height: 20px;
}
:global .react-datepicker__year-dropdown--scrollable,
:global .react-datepicker__month-dropdown--scrollable,
:global .react-datepicker__month-year-dropdown--scrollable {
  // height: 120px;
  overflow-y: scroll;
  scrollbar-color: $primary $color-26;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 14px; /* округлось бегунка */
    border: 9px solid $white; /* отступ вокруг бегунка */
    scrollbar-width: thin;
  }
}
:global .react-datepicker__close-icon {
  right: 8px;
  opacity: 0.8;
  width: 25px;
}
:global .react-datepicker__close-icon::after{
  content: none;    
}
.calWrap {
  position: relative;
}
.customIcon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
  }  
  @media screen and (max-width: $mainMobileBreakPoint) {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    } 
  }
}
.errorText {
  @include h5;
  font-size: 12px;
  font-weight: 400;
  color: $error;
  margin-left: 10px;
}
