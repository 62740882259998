@import "src/assets/mixins";
@import "src/assets/variables";


.filterWindow{
  position: absolute;
  top:0px;
  width: 348px;
  z-index: 15;
  padding: 20px 20px 40px 20px;
  background-color: $color-7;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 360px){  
    width: calc(100% - 12px);
    padding: 20px 0px 40px 0px;
  }

  .filterHeader{
    display: flex;
    justify-content: space-between;
    @include h2mob;
    align-items: center;
    margin-bottom: 24px;
    gap: 15px;
    width: 288px;    
  }
  .mobileSelect{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    align-items: center;
  }
  .mobileFilterbtn{
    display: flex;
    gap: 14px;
    justify-content: center;
  }
  .selectHeader{
    @include h4($color-4);
    margin-bottom: 10px;
  }
}