@import "src/assets/mixins";
@import "src/assets/variables";

.wrap{
  margin-top: 20px;
}

.container{
  margin-top: 10px;
  @media screen and (max-width:$bookingFormBreakPoint2){
    width: 265px;    
  }  
}

.commentView{
  @include h5;
  margin-top: 5px;
  color: $color-4;
  font-weight: 500;
  cursor: not-allowed;
  overflow: hidden;
  word-wrap: break-word;
}