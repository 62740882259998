@import "src/assets/mixins";

.title {
  cursor: default;
  position: relative;

  @include h4();
}

.prompt {
  opacity: 0;
  cursor: default;
  transition: $transition;
  position: absolute;
  transform: translate(-5px, -50%);
  left: calc(100% + 10px);
  top: 50%;
  text-wrap: nowrap;

  @include h5();

  @media (max-width: 1170px) {
    display: none;
  }
}

.allowedWrapper {
  display: flex;
  gap: 4px;

  @media (min-width: 1170px) {
    &:hover .prompt {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
}

.statusErr {
  .title {
    color: $text-error;
  }
}

.statusOk {
  .title {
    color: $color-12;
  }
}
