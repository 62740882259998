@import "src/assets/mixins";

.basicSettingsWrapper {
  display: flex;
  gap: 30px;
  flex: 1;
  position: relative;

  @media (max-width: 1350px) {
    flex-wrap: wrap;
  }

  @media (max-width: 953px) {
    flex-direction: column;
  }

  @media (max-width: 760px) {
    gap: 24px;
  }
}

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 760px) {
    margin-bottom: 25px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }

  span {
    @include h4();
  }
}

.photoWrapper {
  max-width: 280px;
  width: 100%;

  @media (max-width: 953px) {
    order: -1;
  }

  @media (max-width: 953px) {
    max-width: 420px;
    padding-right: 68px;
  }

  .photo {
    aspect-ratio: 280 / 200;

    @media (max-width: 953px) {
      aspect-ratio: 212 / 118;
    }
  }
}

.bookingPhotoWrapper {
  max-width: 420px;
  width: 100%;

  .bookingPhoto {
    aspect-ratio: 420 / 300;

    @media (max-width: 953px) {
      aspect-ratio: 288 / 160;
    }
  }
}

.fieldsWrapper {
  flex: 1;
}

.fields {
  display: flex;
  gap: 22px;

  @media (min-width: 1350px) {
    flex-direction: column;
  }

  @media (max-width: 953px) {
    flex-direction: column;
  }

  @media (max-width: 760px) {
    gap: 16px;
  }
}

.fieldsItem {
  display: flex;
  flex-direction: column;
  gap: 22px;
  flex: 1;

  @media (max-width: 760px) {
    gap: 16px;
  }
}

.btnsContainer {
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.btnsWrapMain {
  display: flex;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

.btnsWrapBooking {
  display: flex;
  gap: 10px;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.openBookingBtnWrap {
  flex-shrink: 0;

  @media (max-width: 480px) {
    display: flex;
    flex: 1;
  }
}

.openBookingBtn {
  @media (max-width: 480px) {
    flex: 1;
  }
}

.copyBtn {
  aspect-ratio: 1;
  height: 100%;
  width: 100%;
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}
