@import "src/assets/mixins";
@import "src/assets/variables";

.title {
  @include h4($text-secondary);
  // height: 20px;
  @media (max-width: 520px) {
    height: auto;
  }
}

.input {
  outline: none;
  border-radius: $radius;
  background-color: $white;
  color: $text-primary;
  width: 100%;
  border: $border-secondary;
  position: relative;
  transition: $transition;
  height: 67px;
  padding-inline: 20px 45px;
  @include b3($text-primary);

  &:focus {
    border: $border-primary;
    box-shadow: $shadow-1;
  }

  &::placeholder {
    @include placeholder();
  }

  @media (max-width: $mainMobileBreakPoint) {
    height: 40px;
    padding-inline: 8px;
  }
}

.hiddenInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  opacity: 0;
  z-index: -10;
  width: 10px;
  cursor: default;
}

.textarea {
  padding: 15px 45px 20px 20px;
  resize: none;
  height: 157px;

  @media (max-width: $mainMobileBreakPoint) {
    padding: 8px;
  }
}

.activeInp {
  border: $border-primary;
}

.inputWrapper {
  position: relative;
}

.passBtn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: $mainMobileBreakPoint) {
    right: 8px;
  }
}

.activePassBtn {
  svg {
    path {
      stroke: $primary;
    }
  }
}

.errorInput {
  border: $border-error;

  &:focus {
    border: $border-error;
  }
}

.errorText {
  @include h5($text-error);
  margin: 5px 0 0 20px;

  @media (max-width: 760px) {
    margin-left: 8px;
  }
}

.disabledInp {
  cursor: not-allowed;
}

.titleWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.small {
  .input {
    height: 33px;
    border: $border-small;
    padding: 0 10px 0 10px;
    &:focus {
      border: $border-primary;
      box-shadow: $shadow-1;
    }
    &.errorInput {
      border: $border-error;

      &:focus {
        border: $border-error;
      }
    }
    &.disabledInp{
      border: $border-secondary;
      box-shadow: none;
    }
  }
  
  .titleWrapper {
    margin-bottom: 5px;
  }
  .title {
    font-size: 14px;
    line-height: 20px;
  }
  .textarea {
    height: 50px;
    padding-top: 2px;
    &.comment {
      height: 120px;
    }
  }
  .errorText {
    @include h5($text-error);
    font-size: 12px;
    font-weight: 400;
    margin: 2px 0 0 10px;
  }
  .disabledInp {
    cursor: not-allowed;
    color: $color-4;
  }

  @media screen and (max-width: $bookingFormBreakPoint1) {
    input {
      height: 32px;
      min-width: 264px;
      padding: 0 8px 0 8px;
      font-weight: 400;
    }
  }
}
.kidsAge{
  .input {
    height: 40px;
    width: 146px;
    padding:5px;
    text-align: center;    
  }
  .errorText {
    margin: 5px 0 0 0px;
  }
  .activeInp {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    .input {
      width: 130px;
       
    }
  }
}