@import "src/assets/mixins";

.wrapper {
  position: relative;
  background-color: $white;
  border-radius: $radius;

  @media (min-width: 1320px) {
    padding: 16px 26px;
  }

  @media (min-width: 760px) {
    padding: 10px;
  }
}

.headerWrapper {
  display: none;

  @media (min-width: 541px) {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 16px;
  @include h3();
}

.mobileTitle {
  @include h3mob();
  margin-bottom: 4px;

  @media (min-width: 541px) {
    display: block;
  }
}

.mobileAllowed {
  position: absolute;
  background-color: $white;
  padding: 5px 8px;
  border-radius: $radius;
  left: 8px;
  top: 8px;

  @media (min-width: 541px) {
    display: none;
  }
}

.footerWrapper {
  display: flex;
  gap: 10px;

  @media (max-width: 540px) {
    flex-direction: column;
    gap: 16px;
  }
}

.imgWrapper {
  border-radius: $radius;
  overflow: hidden;
  background-color: $color-6-1;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 288 / 176;
  width: 100%;
  position: relative;

  @media (min-width: 541px) {
    max-width: 260px;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.footerText {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.description {
  flex: 1;
  // align-self: center;
  max-height: 159px;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  @include h4();

  @media (max-width: 1320px) {
    font-size: 14px;
  }

  @media (min-width: 541px) {
    border-radius: $radius;
    background-color: #f6faff;
    padding: 12px;
  }

  @media (max-width: 540px) {
    font-size: 12px;
    color: $text-secondary;
  }
}

.btnsWrapper {
  display: flex;
  gap: 10px;
}

.mobileBtnsWrapper {
  display: flex;
  justify-content: space-between;

  @media (min-width: 541px) {
    display: none;
  }
}

.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  left: 0;
  top: 0;
  border-radius: $radius;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
