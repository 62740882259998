@import "src/assets/mixins";

.window {
  width: 750px;
  border: 1px solid $primary;
  border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  @media screen and (max-width:$bookingFormBreakPoint1){
    width: 590px;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    width: 288px;
  }
}
