@import "src/assets/mixins";

.newObjectBtnWrappen {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;

  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
}

.cardListWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.emptystateWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .emptystate {
    position: static;
    transform: none;
    margin-bottom: 31px;
  }
}
