@import "src/assets/mixins";

.wrapper {
  position: relative;
  width: 38px;
  display: flex;
  justify-content: center;
  // display: inline-block;
  
}

.btn {
  height: 20px;
  svg path {
    transition: $transition;
  }

  &:hover {
    svg path {
      stroke: $color-4;
    }
  }
}

.activeBtn {
  svg path {
    stroke: $primary;
  }

  &:hover {
    svg path {
      stroke: $primary;
    }
  }
}

.errorBtn {
  svg path {
    stroke: $text-error;
  }

  &:hover {
    svg path {
      stroke: $text-error;
    }
  }
}

.menu {
  position: absolute;
  border: $border-primary;
  border-radius: $radius;
  box-shadow: $shadow-tooltip;
  padding: 12px 10px 12px 20px;
  background-color: $white;
  z-index: 20;
  left: calc(100% + 2px);
  width: 300px;
  user-select: none;

  @media (max-width: 760px) {
    width: 220px;
    padding: 8px 8px 8px 12px;
    left: 0;
    top: calc(100% + 4px);
  }
  

  * {
    @include h4($text-secondary);
    list-style: disc;

    @media (max-width: 760px) {
      font-size: 12px;
    }
  }
}

.requirementsList {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 760px) {
    padding-left: 10px;
  }
}

.errorMenu {
  border-color: $text-error;
  box-shadow: $shadow-tooltip-error;
}
