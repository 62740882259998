@import "src/assets/mixins";
@import "src/assets/variables";
.container{
  padding: 20px 5px 5px 5px;
  background-color: $color-7;
  height: calc(100vh - 140px);
  
  @media screen and (max-width: 1450px){   
    padding: 10px 5px 0px 0px;
  }
  @media screen and (max-width: 1150px){   
    padding: 0px;
    background-color: $white;
  }
  @media screen and (max-width: $mainMobileBreakPoint){   
    button{
      height: 40px;      
    }
  }
  @media screen and (max-width: 480px){   
    top: -10px;
  }
  
}

.leftWrapper, .rightWrapper{
  display: flex;
  cursor: pointer;  
  align-items: center;
}
.calendarWrap{
  display: flex;
  .calendarButton {
    @media screen and (max-width: $mainMobileBreakPoint){ 
      display: none;
    }
  } 
  @media screen and (max-width: 540px){
    display: none;
  } 
}
.leftWrapper {
  gap: 10px;
  justify-content: space-between;  
  @media screen and (max-width: 810px){ 
    width: 100%;
  }
  @media screen and (max-width: 540px){ 
    flex-direction: column;
    // border-top: 1px ;
    // border-style: solid;
    // border-color: $color-18;
    // padding-top: 10px;
  }
}
.todayDesk{
  @media screen and (max-width: $mainMobileBreakPoint){ 
    display: none;
  }
}  
.buttonPanel {
  max-width: 1250px;
  top:0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap:20px;
  @media screen and (max-width: $mainMobileBreakPoint) and (orientation:landscape){  
    margin-bottom: 10px;
  }
  @media screen and (max-width: 810px){ 
    gap: 0px;
  }
  @media screen and (max-width: 540px){ 
    margin-bottom: 10px;
  }
}
.todayButton{
  width: 100px;
  // margin-left: 36px;
}

.next{
  transform: rotate(180deg);
}
.calendarButton {
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

.prev, .next {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: $color-17;
    &  .help {
      display: block;      
      text-wrap: nowrap;
      padding: 4px 8px 4px 8px;
      z-index: 5;
      
    }
  }
  }
  position: relative;
}
.prev:hover .help{
  top:55px;
  left: -15px;
  @media screen and (max-width: $mainMobileBreakPoint){   
    top:38px
  }
  @media screen and (max-width: 540px){   
    top:-26px;
    left: 5px;
  }

} 

.next:hover .help{
  transform: rotate(180deg);
  top:38px;
  left: -15px;
}
.help {
  display: none;
  position: absolute;
  background-color: $color-4;
  border-radius: 6px;
  box-shadow: $shadow-2;
  @include h5;
  font-weight: 400;
  color: $white;

}

.rightWrapper{
  @media screen and (max-width: 700px){   
    display: none;
  }
}

.mobile{
  display: none;
  
  @media screen and (max-width: 810px){   
    display: flex;
    width: 44px;
    font-size: 36px; 
    font-weight: 200;    
    // z-index: 10;
    opacity: 1.00;
  }
  @media screen and (max-width: 700px){   
    display: none;
  }
}
.fixMobile{
  display: none;
  @media screen and (max-width: 700px){   
    display: flex;
    font-size: 36px; 
    font-weight: 200;    
    z-index: 8;
    position: fixed;
    right: 35px;
    bottom: 25px;
    width: 40px;
    height: 40px;
    font-weight: 300;
    opacity: 0.92;
  }
  @media screen and (max-width: 700px){ 
    bottom: 30px;
  }
}
.desctop{
  width: 153px;
  
  @media screen and (max-width: 810px){   
    display: none;
  }
}
.helpDesc {
  @media screen and (max-width: 1150px){ 
    display: none;
  } 
}
.helpMob{
  display: none;
  // @media screen and (max-width: 1150px){   
  //   display: flex;
  //   justify-content: center;
  // }
}
.calendar{
   input{
  width: 356px;
  @media screen and (max-width: 1240px){
    width: 282px;
  }
  @media screen and (max-width: 620px){
    width: 235px;
  }
  // @media screen and (max-width: 540px){
  //   width: 282px;
  // }
  }
 
}

.todayMobile{
  display: none;
  @media screen and (max-width: $mainMobileBreakPoint){ 
      display: flex;
      justify-content: center;
      
  }
  @media screen and (max-width: 540px){ 
    // height: 54px;
    align-items: center;
    width: 100%;
    // border-width: 1px 0px 1px 0px;
    // border-style: solid;
    // border-color: $color-18;
  }
  .todayWrap{
    display: flex;
    width: 313px;
    justify-content: space-between;
      
    
    @media screen and (max-width: 620px){
      width: auto;
    }
    @media screen and (max-width: 540px){
      width: 300px;
    }
  }
}
