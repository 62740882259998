@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  background-color: $white;
  width: inherit;
  box-shadow: $shadow-2;
  padding: 12px 16px 12px 16px;
  gap: 24px;
  margin-bottom: 16px;
  border-radius: $radius;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content:space-between;
  min-width: 513px;
  // &.notViewed{
  //   background-color: rgba($color-13, 0.5); 
  //   border: $border-primary;   
  // }
  @media screen and (max-width: 1440px) { 
    gap: 10px;
    height: auto;
  }

  @media screen and (max-width: $bookingListMobileBreakPoint1) { 
    min-width: 270px;
    height: auto;
    padding: 16px;
    gap:0px;
  }
 
}
.left{
  cursor: pointer;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 200px;
  align-items: center;
  gap:16px;
  
  @media screen and (max-width: $mainMobileBreakPoint) { 
    padding-right: 5%;
  }
  @media screen and (max-width: 670px) { 
    padding-right: 0px;
  }
  @media screen and (max-width: $bookingListMobileBreakPoint1) { 
    display: block;
    border-right: 1px solid $color-13;
    padding-right: 14px;
    overflow: hidden;
  }
  .info{
    display: grid;
    grid-template-columns: 1fr minmax(200px, 2fr);
    align-items: center;
    // justify-items:stretch;
    gap:16px;
    overflow: hidden;
    @media screen and (max-width: 1200px) { 
      display: block;   

    }
  }
  .names{
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: nowrap;
    // white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include b3($text-primary);
    font-weight: 400;  
    
    .container_names{
      overflow: hidden;  
        
    }
    .last_name{  
    width: inherit;
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 100px;
    flex-shrink: 1;
    flex-grow: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    }
    @media screen and (max-width:1200px) { 
      margin-bottom: 8px;
    }
  }
  .objectBlock{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    @media screen and (max-width: 1200px) { 
      margin-bottom: 12px;      
    }
  }
  .icon{
    display: flex;
    align-items: center;
    // padding-top: 4px;
    svg{
      width: 26px;
      height: 26px;

       path{
        stroke: $color-12;
      }
      @media screen and (max-width: $mainMobileBreakPoint) { 
        width: 20px;
        height: 20px;   
      }
    }
    
  }
  .rent{
    @include b2($text-primary);
    line-clamp: 2;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
    text-overflow: ellipsis;
    
  }
}
.personBlock{
  display: flex;
  justify-items: stretch;
  justify-content:space-between;
  gap:16px;
  @media screen and (max-width: 1200px) { 
    display: block;    
    // margin-bottom: 6px;
  }
}
.dates{
  @include h5;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 202px;
  flex-shrink: 0;
  flex-wrap:nowrap;
  
  @media screen and (max-width: 1600px) { 
    width: 100px;
    flex-wrap: wrap;
  }  
  @media screen and (max-width: 1200px) { 
    width: 202px;
    flex-wrap:nowrap;
  }
  @media screen and (max-width: $bookingListMobileBreakPoint1) { 
    margin-bottom: 12px;
  }
  

  .dates_left{
    display: flex;
    align-items: center;
    gap: 4px;
  }
  svg{
    width: 20px;
    height: 20px;
    opacity: 60%;
    flex-shrink: 0;
  }
}
.flag{
  margin-right: 6px;
  svg{
   box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.5);

  }
  @media screen and (max-width: $bookingListMobileBreakPoint1) { 
    margin-left: 3px;
    
  }
}
.right{
  display: flex;
  gap: 10px;
  align-items: center;
  @media screen and (max-width: 1440px) { 
    flex-direction: column;
    padding-left: 16px;
    gap:16px;
    height: inherit;
    border-left: 1px solid $color-13;
  }
  @media screen and (max-width: $bookingListMobileBreakPoint1) { 
    border-left: none;
  }
}
.right{
  position: relative;
  .help {
    display: none;
    position: absolute;
    background-color: $color-4;
    border-radius: 6px;
    box-shadow: $shadow-2;
    @include h5;
    font-weight: 400;
    color: $white;
  }
  .delete:hover ~.help{        
      display: block;
      width: 280px;
      top:40px;
      text-wrap: wrap;
      padding: 4px 8px 4px 8px;
      z-index: 5;
      right: 0px;
    
  }
}
