@import "src/assets/mixins";

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  background-color: rgba(10, 58, 134, 0);
  transition: $transition;
  display: grid;
  place-items: center;
  padding: 28px 15px;
}

.showOverlay {
  background-color: rgba(10, 58, 134, 0.4);
}

.window {
  background-color: $color-7;
  border-radius: $radius;
  position: relative;
  transition: opacity $transition, transform $transition;
  border: $border-primary;
  opacity: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.notCustom {
  transform: translateY(40px);
  padding: 30px;
  max-width: 516px;
  width: 100%;

  @media (max-width: 760px) {
    padding-top: 16px;
    padding-inline: 16px;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 26px;
  @include h3();
  padding-right: 25px;
  @media screen and (orientation:landscape ) and (max-height:400px){
    margin-bottom: 10px;
  }
}

.btnWrapper {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  @media screen and (orientation:landscape ) and (max-height:400px){
    margin-top: 10px;
  }
}

.showWindow {
  transform: translateY(0);
  opacity: 1;
}

.closeBtn {
  padding: 2px 11px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;

  @media (max-width: 760px) {
    top: 16px;
    right: 5px;
  }
}
