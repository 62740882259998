@import "src/assets/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 760px) {
    gap: 10px;
  }
}

.imgWrapper {
  border-radius: $radius;
  position: relative;
  overflow: hidden;
  background-color: $color-6-1;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 250 / 145;
  width: 100%;
  height: auto;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.transparentImgWrapper {
  background-color: transparent;
}

.dragOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition;
  cursor: default;
  @include b3();
  opacity: 0;
  background-color: #696f79d2;
}

.isDragging {
  opacity: 1;
}

.btnsWithTooltipWrapper {
  display: flex;
  gap: 10px;
}

.btnsImagesListWrapper {
  display: flex;
  gap: 40px;
  // justify-content: space-between;
}

.removeImageBtn {
  color: $text-error;
}

.errorText {
  margin-top: 10px;
  @include h5($text-error);
}
