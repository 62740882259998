@import "src/assets/mixins";

.rentalObjectListWrapper {
  display: grid;
  grid-gap: 32px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

.objectsEditingWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
}

.newObjectBtnWrapper {
  margin-top: 33px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.newObjBtnWrapper {
  @media (max-width: 540px) {
    flex: 1;
  }
}

.newObjBtn {
  @media (max-width: 540px) {
    width: 100%;
  }
}

.emptyState {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  p {
    @include h4($text-secondary);
  }
}
