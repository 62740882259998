@import "src/assets/mixins";

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
}

.roomCardListWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 25px;
}

.mainBtnsWrapper {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  flex: 1;
  justify-content: flex-end;

  @media (min-width: 481px) {
    gap: 20px;
    align-items: flex-end;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}

.addBtn {
  @media (max-width: 760px) {
    display: flex;
    justify-content: center;
  }
}
