@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 251px;
  box-shadow: $shadow-1;
  z-index: 20;
  background-color: $white;
  border-radius: $radius;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .text{
    text-transform: capitalize;
    @include b2($text-primary);
    padding-left: 16px;
  }
  .btnClose{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-6;
    transform: rotate(45deg);
    font-size: 36px;
    font-weight: 100;
  }
}
.info{
  padding: 16px 0 17px 16px;
}
.info>div{
  display: flex;
  gap: 10px;
  @include h4mob($text-primary);
  margin-bottom: 8px;
}
