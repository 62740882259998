@import "src/assets/mixins";
@import "src/assets/variables";

.container {
  width: 300px;
  height: 560px;
  border: 1px solid $color-13;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  background-color: $white;
  &:hover {
    box-shadow: $shadow-1;
  }
  @media screen and (max-width: $mainMobileBreakPoint) {
    box-shadow: $shadow-1;
    height: 520px;
  }
}
.label {
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include reg12mobSemi($primary);
  
}

.header {
  @include h2;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 16px;
  @media screen and (max-width: $mainMobileBreakPoint) {
    font-size: 20px;
  }
}
.price, .free {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
}
.price {  
  @include h2mob;
  color: $color-12;
  .third {
    font-size: 14px;
  }
}
.free {
  @include b3($text-secondary);
  font-weight: 600; 
}
.featBlock {
  margin: 30px 16px 10px 16px;
  .line {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    @media screen and (max-width: $mainMobileBreakPoint) {
      @include b3($text-primary);
    }    
  }
}

.paying {
  border-color: $color-12;
  .label {
    background-color: $color-20;
    color: $color-21;
  }
  &.current{
    border-color: $color-13;
  }
}

.current {  
  .label {
    background-color: $color-7;
    color: $primary;
  }
}
.btnContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  button {
    width: 152px;
  }  
}

