@import "src/assets/mixins";
@import "src/assets/variables";

.note {
  margin-bottom: 15px;
  margin-top: 10px;
  @include h3mob ($text-primary);
  font-weight: 600;
  @media screen and (max-width: 600px), (orientation:landscape ) and (max-height:400px){
    font-size: 16px;
  }
}

.btnGroup {
  @include b2 ($text-primary);
  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    input {
      margin: auto 0;
      height: 20px;
      width: 20px;
      flex-shrink: 0;
    }
    @media screen and (orientation:landscape ) and (max-height:400px){
      margin-bottom: 10px;
    }
  }
}