@import "src/assets/variables";
@import "src/assets/mixins";

.container {
  @include b3;
  color: $primary;
  background-color: $white;
  padding: 16px 10px 16px 10px;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  gap: 10px;
  div {
    display: flex;
    align-items: center;
    gap: 8px;    
  }
  @media screen and (max-width: 1150px){   
    flex-direction: row;
    padding-top: 0px;
    justify-content: center;
    height: 40px;
    // background-color:$color-7;
  }
  @media screen and (max-width: 540px){   
   padding-bottom: 5px;
  }
  @media screen and (max-width: 480px){   
    flex-direction: column;
    gap: 5px;
  }
}