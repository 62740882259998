@import "src/assets/mixins";
@import "src/assets/variables";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $primary;
  gap: 30px;
  padding: 30px;
  @media screen and (max-width: 520px){     
    gap: 15px;
    padding: 10px;
  }
}
.title {
  font-size: 128px;
  font-weight: 600;  
  line-height: 130px;
  @media screen and (max-width: 1048px){     
    font-size: 84px;
    line-height: 84px;
  }
  @media screen and (max-width: $mainMobileBreakPoint){     
    font-size: 64px;
    line-height: 64px;
  }
  @media screen and (max-width: 520px){     
    font-size: 48px;
    line-height: 48px;
  }
}
.middleLine{
  display: flex;
}
.middleTitle{
  font-size: 64px;
  font-weight: 400; 
  line-height: 78px; 
  color: $primary;  
  @media screen and (max-width: $mainMobileBreakPoint){     
    font-size: 36px;
    line-height: 36px;
    padding: 0px;
  }
  @media screen and (max-width: 520px){     
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    padding: 0px;
  }
}
.content{
  font-size: 24px;
  font-weight: 500; 
  line-height: 30px; 
  text-align: center;
  @media screen and (max-width: $mainMobileBreakPoint){     
    font-size: 20px;   
    line-height: 28px; 
  }
  @media screen and (max-width: 520px){     
    font-size: 14px;   
    line-height: 22px; 
  }
}
.btnWrap{
  width: 136px;
  button {
    height: 51px;
    font-size: 16px;
    @media screen and (max-width: $mainMobileBreakPoint){  
      height: 40px;
      font-size: 14px;
    }    
  }
}
.socket{
  background: url("./../../assets/img/socket-pict.svg") no-repeat;
  width: 74px;
  height: 75px;
  position: relative;
  left: -10px;
  bottom: 13px;
  @media screen and (max-width: $mainMobileBreakPoint){   
    width: 39px;
    height: 40px;
    background-size: contain;
    left: -5px;
    bottom: 9px;
  }
  @media screen and (max-width: 520px){    
    width: 25px;
    height: 25px;
    left: -3px;
    bottom: 8px;
  }
}
