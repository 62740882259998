@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-radius: $radius;
  height: 76px;
  width: 80%;
  @media screen and (max-width: 1440px)  {
    width: 90%;
  }
  }

  .btn {
    border-radius: $radius;
    box-shadow: $shadow-1;
    padding: 16px;
    min-width: 190px;
    @include b3($primary);
    @media screen and (max-width: 1340px){      
       padding: 5px;
       min-width: 0px;
       height: 80px;
       width: 140px;
       max-width: 140px;
      
    } 
    @media screen and (max-width: 1050px){      
      width: auto;
      max-width: 130px;
     
   } 
    svg {
      height: 23px;
      width: 24px;
      path{
        stroke: $color-12;
      }
    }
   &.activeBtn{
    background-color: $color-13;
    color:$text-primary;
    box-shadow: none;
    svg {
      path{
        stroke: $text-primary;
      }
    }
   } 

  
}