@import "src/assets/mixins";
@import "src/assets/variables";

.wrap{
  margin-top: 20px;
  
}
.container{
  display: grid;
  grid-template-columns: 345px 345px;
  column-gap:20px;
  row-gap: 10px;
  justify-content: center;
  padding-top: 12px;
  @media screen and (max-width:$bookingFormBreakPoint1){
    grid-template-columns: 265px 265px;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    grid-template-columns: 265px;
  }
}