@import "src/assets/mixins";

.iti__flag {
  background-image: url("../.././../node_modules/intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../.././../node_modules/intl-tel-input/build/img/flags@2x.png");
  }
}

.intlTelInput {
  position: relative;

  &__title {
    &-wrapper {
      height: 20px;
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    @include h4($text-secondary);
  }

  &__input {
    position: relative;
    outline: none;
    border-radius: $radius;
    background-color: $white;
    border: $border-secondary;
    height: 67px;
    width: 100%;
    @include b3($text-primary);
    // padding: 0 20px 0 90px !important;

    @media (max-width: $mainMobileBreakPoint) {
      height: 40px;
    }

    &:focus {
      border: $border-primary;
      box-shadow: $shadow-1;
    }

    &::placeholder {
      @include placeholder();
    }

    &-active {
      border: $border-primary;

      // ~ .iti__flag-container > .iti__selected-flag {
      //   border-right: $border-primary;
      // }
    }

    &-error {
      border: $border-error;

      &:focus {
        border: $border-error;
      }
    }

    &-hide {
      position: relative;
      &::before {
        content: "";
        width: calc(100% - 2px);
        height: 65px;
        background-color: $white;
        border-radius: $radius;
        position: absolute;
        left: 1px;
        top: 1px;
        z-index: 2;
      }
    }
  }

  &__errText {
    @include h5($text-error);
    margin: 5px 0 0 20px;

    @media (max-width: $mainMobileBreakPoint) {
      margin-left: 8px;
    }
  }
}

.iti {
  width: 100%;

  &__selected-flag {
    border-right: $border-secondary;
    padding-inline: 20px 15px;
    background-color: unset !important;

    @media (max-width: $mainMobileBreakPoint) {
      padding-inline: 6px;
    }
  }

  &__country-list {
    width: 455px;
  }

  &__arrow {
    border: 0;
    width: 12px;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 7px;
      background-color: $color-4;
    }

    &::after {
      transform: rotate(-45deg);
      left: 4.5px;
    }

    &::before {
      transform: rotate(45deg);
      left: 0;
    }
  }

  &__selected-dial-code {
    @include h4($text-secondary);
  }
}
.small {
  .intlTelInput__title {
    @include h5($text-secondary);
    font-weight: 400;
    &-wrapper {
      margin-bottom: 5px;
    }
  }
  .intlTelInput__errText {
    @include h5;
    font-size: 12px;
    font-weight: 400;
    color: $error;
    margin: 2px 0 0 10px;
  }
  .intlTelInput__input {
    height: 33px;
    border: $border-small;

    &:focus {
      border: $border-primary;
    }
    &-error {
      border: $border-error;

      //   &:focus {
      //     border: $border-error;
      //   }
    }
    &-disabled {
      cursor: not-allowed;
      color: $color-4;
    }
  }
  #phone {
    padding-left: 122px !important;
  }
  @media screen and (max-width: $bookingFormBreakPoint1) {
    .iti {
      &__selected-flag {
        padding: 0 5px 0 5px;
      }
    }
    #phone {
      padding-left: 97px !important;
    }
  }
}
