$primary: rgb(24, 85, 183);
$white: #ffffff;
$primaryGradient: linear-gradient(
  0deg,
  rgba(24, 85, 183, 1) 0%,
  rgba(38, 144, 247, 1) 50%,
  rgba(24, 85, 183, 1) 100%
);
$color-4: rgb(105, 111, 121);
$color-5: rgba(255, 255, 255, 0.2);
$color-6: rgb(134, 146, 166);
$color-6-1: rgba(134, 146, 166, 0.3);
$color-7: rgb(246, 250, 255);
$color-8: rgb(10, 58, 134);
$color-9: rgb(163, 235, 170);
$color-10: #ffde69;
$color-11: #e093ec;
$color-12: rgb(66, 172, 77);
// Olya
$color-13: rgba(226, 234, 255, 1);
$color-14: rgba(255, 59, 48, 1);
$color-15: rgba(237, 237, 237, 1);
$color-16: rgba(0, 0, 0, 0.1);
$color-17: rgba(225, 238, 253, 1);
$color-18: rgba(238, 238, 238, 1);
$color-19: rgba(248, 212, 212, 1);
$color-20: rgba(210, 249, 214, 1);
$color-21: rgba(10, 73, 16, 1);
$color-22: rgba(255, 233, 156, 1);
$color-23: rgba(88, 55, 17, 1);
$color-24: rgba(239, 228, 253, 1);
$color-25: rgba(70, 11, 147, 1);
$color-26: rgba(246, 248, 255, 1);
$color-27: rgba(249, 244, 255, 1);
$color-28: rgba(255, 243, 200, 1);
$color-29: rgba(238, 255, 240, 1);
$color-30: rgba(255, 234, 234, 1);
$color-31: rgb(105, 111, 121, 0.5);
$color-32: rgba(199, 228, 255, 1);
$color-33: rgba(254, 207, 161, 1);
$color-34: rgba(88, 55, 17, 1);
$color-35: rgba(228, 240, 255, 1);
$color-36: rgba(255, 249, 249, 1);
$color-37: rgba(162, 187, 255, 1);

$error: rgb(185, 18, 18);

$text-primary: rgb(15, 38, 100);
$text-white: #ffffff;
$text-secondary: #696f79;
$text-error: $error;

$border-primary: 1px solid rgb(24, 85, 183);
$border-light: 1px solid rgb(24, 85, 183, 0.5);
$border-secondary: 1px solid rgb(105, 111, 121);
$border-white: 1px solid #ffffff;
$border-error: 1px solid $error;
$border-table: 0.25px solid rgba(0, 0, 0, 0.1);
$border-small: 0.25px solid rgb(105, 111, 121, 0.5);

$shadow-1: 0px 4px 7px 3px rgba(24, 85, 183, 0.11);
$shadow-2: 0px 4px 7px 0px rgba(0, 0, 0, 0.1), 0px -1px 7px 0px rgba(0, 0, 0, 0.1);
$shadow-error: 0px 0px 15px 3px rgba(185, 18, 18, 0.3);
$shadow-tooltip: 0px 4px 11px 3px rgba(10, 32, 68, 0.18);
$shadow-tooltip-error: 0px 2px 11px 0px rgba(48, 0, 0, 0.3);

$radius: 6px;
$disabled-opacity: 0.3;
$transition: 0.3s;

$bookingFormBreakPoint1: 770px;
$bookingFormBreakPoint2: 640px;
$mainMobileBreakPoint: 932px;
$bookingListMobileBreakPoint1: 600px;
$bookingListMobileBreakPoint2: 460px;
