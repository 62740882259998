@import "src/assets/mixins";

.container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.left {
  background-image: url("../../assets/img/bg-form-page.svg"), $primaryGradient;
  background-size: cover;
  background-position: center;
  padding: 50px 132px;
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 46px;

  @media (max-width: 1300px) {
    padding-inline: 60px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    padding-block: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px 16px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  // align-items: center;
  max-width: 456px;
  gap: 15px;
  width: 100%;

  @media (max-width: 1300px) {
    max-width: unset;
  }

  @media (max-width: 480px) {
    gap: 5px;
  }
}

.possWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 456px;
  width: 100%;

  @media (max-width: 1300px) {
    max-width: unset;
  }

  @media (max-width: 760px) {
    display: none;
  }
}

.right {
  background-color: $white;
  width: 50%;
  display: flex;
  align-items: center;

  padding: 70px 72px 30px;

  @media (max-width: 1300px) {
    padding-inline: 60px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 480px) {
    padding: 16px;
  }
}

.formWrapper {
  max-width: 456px;
  width: 100%;
  position: relative;
}

.logo {
  @media (max-width: 760px) {
    height: auto;
    width: 200px;
  }

  @media (max-width: 480px) {
    width: 110px;
  }
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 1em;
  color: $white;

  @media (max-width: 760px) {
    font-size: 20px;
    font-weight: 500;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.formTitle {
  margin-bottom: 20px;
  @include h2();

  @media (max-width: 760px) {
    margin-bottom: 28px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.description {
  color: $text-secondary;
  margin-bottom: 30px;

  @media (max-width: 760px) {
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.possItem {
  border: $border-white;
  height: 67px;
  width: 100%;
  border-radius: $radius;
  align-items: center;
  display: flex;
  padding: 0 20px;
  gap: 10px;
  cursor: default;

  @include h3($white);
}
