@import "src/assets/mixins";
@import "src/assets/variables";


.container{  
  margin-top: 15px;
  margin-bottom: 20px;
}
.line{
  display: flex;
  justify-content: space-between;
  gap:20px;
  margin-top: 10px;
  @media screen and (max-width:$bookingFormBreakPoint2){
    align-items: start;
    gap:8px;
    &.firstLine{
      flex-direction: column;
    }
    &.secondLine{
      flex-direction: column;
    }
  }
}
.firstLine{
  .column:first-of-type{
    z-index: 3;
  }
}
.column {
  width: 345px;
  &.dateBlock{
    display: flex;
    gap:29px;
    flex-wrap: wrap;
    &.edit{
      display: block;
      .out{
        margin-top: 10px;
      }
       
    }
  }
  @media screen and (max-width:$bookingFormBreakPoint1){
    width: 265px;
  }
}
.priceBlock{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  .inputsumm{
    width: 120px;
    @media screen and (max-width:$bookingFormBreakPoint2){
      width: 160px;
    }
    input{
      width: 120px;
      text-align: right;
      min-width: 120px;
      @media screen and (max-width:$bookingFormBreakPoint1){
        font-weight: 500;
      }
      @media screen and (max-width:$bookingFormBreakPoint2){
        width: 160px;
      }
    }
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: block;
  }
}
.btnSumm {
  // align-self: center;
  button {  
    height: 37px;
    @include b3;
   }
   @media screen and (max-width:$bookingFormBreakPoint2){
    margin-top: 10px;
  }
}
.totalLine{
  @include h5;
  font-weight: 500;
  color: $text-primary;  
  display: flex;
  align-items: center;
  gap: 5px;
  input {
    border: $border-primary !important;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: block;
  }
}
.totalTitle{
  padding-right: 10px;
  @media screen and (max-width:$bookingFormBreakPoint1){
    width: 200px;
    flex-grow: 0;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: none;
  }
}
.totalTitleMob{
  display: none;
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: block; 
    margin-bottom: 8px;
  }
}
.inputLine {
  display: flex;
  align-items: center;
}
.payLine{
  display: flex;
  align-items: center;
  gap: 5px;
  @include h5;
  font-weight: 400;
  color: $color-4;
  padding-left: 5px;
  margin-top: 5px;
  &:first-of-type {
    input{
      border: $border-primary !important;
    }
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    padding-left: 0px;
    display: block;
  }

}
.currency {
  @include h5;
  font-weight: 500;
  color: $text-primary;  
  margin-left: 5px;
}
.list{
  list-style-type:disc;
  list-style-position: inside;
  
}
.payTitle{
  margin-right: 15px;
  margin-left: 10px;
  @media screen and (max-width:$bookingFormBreakPoint2){
    margin-left: 0px;
    margin-bottom: 4px;
  }
}
.rest{
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: none;
  }
}
.restMob {
  display: none;
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: block;
    margin-bottom: 4px;
  }
}
.marker{
  font-size: 28;
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: none;
  }
}
.animalBlock{
  margin-top: 11px;
  @include h5;
  font-weight: 500;
  color: $text-primary;
  .animalLine{
    display: flex;    
    align-content: center;
    .checkbox{
      margin-left: 15px;
    }

    .tooltip{
      gap:0px;
      left :-15px;
    }
  }
  .animalTitle {
    display: flex;
    align-items: center;
    &.disabled{
      color: $color-4;
      font-weight: 400;
    }
  }
  .animalInfo{
    margin-top: 5px;
    color: $color-4;
    font-weight: 500;
    overflow: hidden;
    word-wrap: break-word;
  }
}
.viewTime{
  @include b3;
  width: 80px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: $border-small;
  border-radius: $radius;
  color: $color-4;
  
  
}
.errorText{  
  @include h5;
  font-size: 12px;
  font-weight: 400;
  color: $error;
  margin-left: 10px;
  &.summError{
    margin-left: 20px;
    @media screen and (max-width:$bookingFormBreakPoint2){
      display: block;
      margin-left: 5px;
    }

  }
}
.editTime{
  height: 33px;
  width: 120px;
  background-color: $white;
  border: $border-small;
  border-radius: $radius;
  color: $color-4;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .leftTime{
    @include b3;
    color: $color-4;
    width: 56px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: $border-small;
  }
  .rigthTime{
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
  }
}
.commentView{
  @include h5;
  margin-top: 5px;
  color: $color-4;
  font-weight: 500;
  cursor: not-allowed;
  overflow: hidden;
  word-wrap: break-word;
}