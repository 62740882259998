@import "src/assets/mixins";
@import "src/assets/variables";


.title {
  @include h5;
  font-weight: 400;
  color: $text-secondary;
  margin-bottom: 7px;
  
}
