@import "src/assets/mixins";

.tabsContainer {
  max-width: 1300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.tabsList {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 1px;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab {
  flex: 1;
  padding: 20px 10px;
  text-align: center;
  cursor: pointer;
  border-radius: $radius $radius 0 0;
  border: 1px solid transparent;
  transition: $transition;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include h4($text-secondary);
  font-weight: 500;

  &:hover {
    color: $text-primary;
  }

  @media (max-width: 760px) {
    border-bottom: 0.5px solid $color-4;
    padding: 10px 5px;
    font-size: 12px;
  }
}

.activeTab {
  border: $border-primary;
  border-bottom: 0;
  background-color: #f6faff;
  position: relative;
  pointer-events: none;
  color: $text-primary;

  &::before {
    content: "";
    position: absolute;
    background-color: #f6faff;
    width: 100%;
    height: 10px;
    bottom: -5px;
    left: 0;
  }

  @media (max-width: 760px) {
    border: 1px solid transparent;
    border-bottom: 2px solid $text-primary;
    background-color: $white;

    &::before {
      background-color: $white;
      bottom: 0px;
    }
  }
}

.disableTab {
  cursor: not-allowed;

  &:hover {
    color: $text-secondary;
  }
}

.contentWrapper {
  padding: 40px;
  border-radius: $radius;
  border: $border-primary;
  background-color: #f6faff;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 760px) {
    padding: 20px 10px;
    background-color: $white;
    border: none;
  }
}

.firstTabActive {
  border-radius: 0 $radius $radius $radius;
}

.lastTabActive {
  border-radius: $radius 0 $radius $radius;
}
