@import "src/assets/mixins";

.label {
  @include h3($text-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.title{
  @include b2($text-primary);
  margin-bottom: 25px;
}
.note{
  @include b2($text-secondary);
  margin-bottom: 12px;
}

.adultContainer>div {
  margin-bottom: 22px;
}
.itemLine {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;  
}
.kidsContainer{
  padding-left: 37px;
  .itemLine {
    align-items: center;
  }
  @media screen and (max-width: 560px){
    padding-left: 0px;
  }
}
.kidsAges {
  max-width: 147px;
  p{
    padding: 0px;
  }
  input{
    height: 40px;
    width: 140px;
    padding:5px;
    text-align: center;
  }

}

.text{
  color: $text-secondary;
}
.btnWrap, .btnFormWrap {
  margin-top: 20px;
  display: flex;
  gap: 25px;
}
.btnFormWrap {
  justify-content: flex-end;
}

.btnAdd{  
  width: 310px;
}
@media screen and (max-width: 600px){
  .btnAdd, .btnRemove{
    width: auto;
    button{  
      padding-left: 0px;
      padding-right: 0px;
      width: 40px;
      font-size: 36px; 
      font-weight: 200; 
    }
  }
}