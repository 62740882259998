@import "src/assets/mixins";
@import "src/assets/variables";
.wrap{
  margin-top: 20px;
}
.container{
  display: flex;
  justify-content: space-between;
  gap:20px;
  @media screen and (max-width:$bookingFormBreakPoint2){
    display: block;
  }
}
.leftColumn, .rightColumn {
  margin-top: 10px;
  width: 345px;
  @media screen and (max-width:$bookingFormBreakPoint2){
    width: 265px;
  }
}
.leftColumn{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputСontainer {
  padding-top: 10px;
}

.line{
  margin-top: 11px;
}