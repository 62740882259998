@import "src/assets/variables";

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
}

.nextBtn {
  margin-top: 30px;
}

.backBtn {
  align-items: center;
  position: absolute;
  left: -30px;
  top: -45px;
  display: flex;
  gap: 10px;
  color: $text-secondary;
  cursor: pointer;

  @media (max-width: 480px) {
    left: 0;
    top: 2px;

    span {
      display: none;
    }
  }
}

.policy {
  cursor: pointer;
  color: $primary;
}

.checkbox {
  margin-top: 15px;
}

.formHide {
  display: none;
}
