@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 224px;
  border-radius: $radius;
  border-left: 5px solid;
  box-shadow: $shadow-2;
  padding: 8px 14px 16px 12px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.last{
    border-color: $color-18;
  }
  &.await{
    border-color: $color-19;
  }
  &.full_paid{
    border-color: $color-20;    
  }
  &.part_paid {
    border-color: $color-22;
  }
  &.not_paid {
    border-color: $color-24;
  }
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;  
}
.statusLine{
  display: flex;
  align-items: center;
  gap:8px;

  .statusIcon{
    width: 20px;
    height: 20px;
  }
  .statusName{
    @include b2($text-primary)
  }
}
.personLine{
  display: flex;
  gap:8px;
  @include h4mob($text-primary);
  max-width: inherit;

  .person{
    max-width: 190px;
    display: flex;
    gap:5px;
    text-transform: capitalize;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // max-width: 190px;
    }
  }
}
.countLine div{
  display: flex;
  gap: 5px;
  align-items: center;

  .countName{
    @include b3($text-primary);
  }
  .summ{
    @include h4mob($text-primary);
  }
}
.link{
  @include b3($primary);
}
.dates{
  @include h5;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  flex-wrap:wrap;  

  .dates_left{
    display: flex;
    align-items: center;
    gap: 4px;
  }
  svg{
    width: 20px;
    height: 20px;
    opacity: 60%;
    flex-shrink: 0;
  }
}