@import "src/assets/mixins";

.wrapper {
  box-shadow: $shadow-1;
  border-radius: $radius;
  border: $border-primary;
  position: relative;
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 700px) {
    padding: 15px;
    gap: 10px;
  }

  @media (max-width: 520px) {
    padding: 10px;
  }
}

.objectTitle {
  @include h3();
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
}

.imgWrapper {
  background-color: $color-6;
  width: 100%;
  height: 302px;
  border-radius: $radius;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 520px) {
    height: 200px;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.objectInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  @media (max-width: 700px) {
    gap: 10px;
  }
}

.objectType {
  @include h4();
}

.objectInfoItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include b3($text-primary);
}

.objectInfoTitle {
  @include h4($text-secondary);
  white-space: nowrap;
}

.objectInfoDescrWrapper {
  display: flex;
}

.objectInfoDescr {
  display: flex;
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
}

.fullAdressText {
  @include h4();
}

.cardBtnWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: 520px) {
    gap: 10px;
  }
}

.onlyCountry {
  flex-direction: row;
  // align-items: center;
}

.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  left: 0;
  top: 0;
  border-radius: $radius;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
