@import "src/assets/mixins";
@import "src/assets/variables";

.mainContainer {
  background-color: $color-7;
  padding: 20px;
  position: relative;
  border-bottom-left-radius: $radius ;
  border-bottom-right-radius: $radius;
  @media screen and (max-width:$bookingFormBreakPoint2){
    padding: 12px ;
  }
}

.btnSave {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 60px;
  button {  
    height: 37px;
    @include b3;
  }
  @media screen and (max-width:$bookingFormBreakPoint2){
    // position: sticky;
    margin-right: 0px;
    bottom: 10px;
    // left: 50%;
    justify-content: center;
  }
}