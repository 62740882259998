@import "src/assets/mixins";

.imagesContainer {
  position: relative;
  margin-bottom: 15px;
  border-radius: $radius;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  text-align: center;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-gap: 30px;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 30px;
  // justify-content: space-between;
}

.imageList {
  display: grid;
  position: relative;
  gap: 16px;

  @media (min-width: 460px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 761px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

.emptuImagesContainer {
  height: 200px;
  border: 2px dashed $color-6-1;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    @include b2($color-6);
  }
}

.header {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }
}

.title {
  @include h3();

  @media (max-width: 760px) {
    font-size: 16px;
  }
}

.imgWrapper {
  border-radius: $radius;
  position: relative;
  overflow: hidden;
  cursor: grab;
  //   background-color: $color-6-1;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // width: 290px;
  aspect-ratio: 294 / 170;
  width: 100%;
  //   margin-bottom: 16px;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.errorImgWrapper {
  border: $border-error;
  box-shadow: $shadow-error;
}

.imageBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

// .transparentImgWrapper {
//   background-color: transparent;
// }

.dragOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition;
  cursor: default;
  @include b3();
  opacity: 0;
  z-index: 0;
  background-color: #696f79d2;
}

.isDragging {
  opacity: 1;
  z-index: 5;
}

// .btnsWithTooltipWrapper {
//   display: flex;
//   gap: 10px;
// }

// .btnsImagesListWrapper {
//   display: flex;
//   gap: 40px;
//   // justify-content: space-between;
// }

// .removeImageBtn {
//   color: $text-error;
// }

.errText {
  margin-top: 5px;
  @include h5($text-error);
}

.imgAddBtnWrapper {
  display: flex;
  gap: 30px;
  align-items: center;

  .errText {
    margin-top: 0;
  }
}

.errContainer {
  border: $border-error;
  box-shadow: $shadow-error;
}
