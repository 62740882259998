@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 200px;
  border-radius: $radius;
  @include b3;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // align-items: center;
  padding: 6px 16px 6px 16px;
  @media (max-width: $bookingListMobileBreakPoint2) { 
    width: 188px;    
    padding: 8px 12px 8px 12px;
    justify-content: flex-start;
  }
}

.last{
  background-color: $color-18;
  color: $color-4;
  .tt_icon {
    svg path{
      stroke:$color-4;
    }
  }
  .flag {
    opacity: 35%;
  }
}
.new{
  background-color: $color-32;
  color: $text-primary;
}
.que{
  background-color: $color-33;
  color: $color-34;
}
.await{
  background-color: $color-19;
  color: $error;
  .tt_icon {
    svg path{
      stroke:$error;
    }
  }
}
.full_paid{
  background-color: $color-20;
  color: $color-21;
  .tt_icon {
    svg path{
      stroke:$color-21;
    }
  }
}
.part_paid {
  background-color: $color-22;
  color:$color-23;
  .tt_icon {
    svg path{
      stroke:$color-23;
    }
  }
}
.not_paid {
  background-color: $color-24;
  color:$color-25;
  .tt_icon {
    svg path{
      stroke:$color-25;
    }
  }
}
.text{
  text-align: center;
  font-weight: 600;
}
.money{
  display: inline-block;
  width: 42px;
}