@import "src/assets/mixins";

.titleWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.title {
  @include h4($text-secondary);
}

.input {
  outline: none;
  border-radius: $radius;
  background-color: $white;
  color: $text-primary;
  width: 100%;
  border: $border-secondary;
  position: relative;
  transition: $transition;
  height: 67px;
  padding: 0 45px 0 20px;
  @include b3($text-primary);

  &:focus {
    border: $border-primary;
    box-shadow: $shadow-1;
  }

  &::placeholder {
    @include b3($text-secondary);
  }

  @media (max-width: $mainMobileBreakPoint) {
    height: 40px;
    padding-inline: 8px;
  }
}

.activeInp {
  border: $border-primary;
}

.errorInput {
  border: $border-error;

  &:focus {
    border: $border-error;
  }
}

.errorText {
  @include h5($text-error);
  margin: 5px 0 0 20px;

  @media (max-width: 760px) {
    margin-left: 8px;
  }
}

.wrapper {
  width: 100%;
}

.btn {
  text-align: left;
}

.dropDown {
  :global {
    .react-datepicker {
      border-radius: $radius;

      @include b2($text-primary);

      &__time {
        &-list {
          max-height: 140px;

          &::-webkit-scrollbar {
            width: 16px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $primary;
            border-radius: 7px;
            border: 3px solid $white;
            border-block-width: 0;
          }

          &-item {
            padding: 5px 20px !important;

            &--selected {
              background-color: $primary !important;
            }
          }
        }

        &-container {
          width: 120px;
        }

        &-box {
          width: 100% !important;
          text-align: unset !important;
        }
      }
    }
  }
}
