@import "src/assets/mixins";

.customSelectV2 {
  &__title {
    @include b3($text-secondary);
    padding: 24px 20px;
    border: $border-secondary;
    border-radius: $radius 0 0 $radius;
    transition: $transition;
    background-color: $white;

    @media (max-width: $mainMobileBreakPoint) {
      padding: 8px;
    }

    &--filled {
      border: $border-primary;
      color: $text-primary;
      box-shadow: none;
    }

    &--focusedFilled {
      border: $border-primary;
      color: $text-primary;
      box-shadow: $shadow-1;
    }

    &--error {
      border: $border-error;
    }

    &--menuOpened {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }

  &__errText {
    @include h5($text-error);
    margin: 5px 0 0 20px;
  }

  &__wrapper {
    display: flex;
  }

  // &__errText {
  //   @include h5($text-error);
  //   position: absolute;
  //   bottom: -18px;
  //   left: 20px;
  // }

  &__control {
    padding-inline: 20px;
    height: 100%;
    border-radius: 0 $radius $radius 0;
    width: 100%;
    border: $border-secondary;
    cursor: pointer !important;
    gap: 10px;

    @include b3($text-secondary);

    @media (max-width: $mainMobileBreakPoint) {
      padding-inline: 8px;
      gap: 4px;
    }
  }

  // &__indicators {
  //   gap: 3px;
  // }

  // &__indicator {
  //   > svg > path {
  //     fill: $color-4 !important;
  //   }
  // }

  // &__placeholder {
  //   @include b3($text-secondary);
  // }

  &__menu {
    left: 0;
    top: 50% !important;
    transform: translateY(-50%);
    border-radius: $radius;
    padding: 0;
    border: $border-primary;
    box-shadow: $shadow-1;
    background-color: $white !important;
    padding: 10px 10px 10px 20px;

    @media (max-width: $mainMobileBreakPoint) {
      padding: 5px 2px 5px 10px;
    }

    &-list {
      &::-webkit-scrollbar {
        width: 24px;

        @media (max-width: $mainMobileBreakPoint) {
          width: 12px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 14px;
        border: 9px solid $white;

        @media (max-width: $mainMobileBreakPoint) {
          border-width: 2px;
        }
      }
    }
  }

  &__option {
    cursor: pointer !important;
    overflow: hidden;
    @include b3();
    margin-bottom: 10px;

    &:hover {
      // background-color: rgba(0, 0, 0, 0.05);
      color: $color-8 !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // &-disabled {
  //   cursor: not-allowed;
  // }

  // &__noOptionsMessage {
  //   background-color: black;
  // }
}

.customSelectV2-indicator {
  .customSelectV2__dropdown-indicator {
    > svg > path {
      stroke: $white;
    }
  }
}
.booking {
  height: 33px;
  align-items: center;
  .customSelectV2__title {
    padding: 0;
    height: 33px;
    align-items: center;
    padding-left: 10px;
    padding-top: 8px;
    width: 200px;
    @media screen and (max-width: $bookingFormBreakPoint1) {
      width: 160px;
      font-size: 12px;
      padding-left: 8px;
    }
  }
  .customSelectV2__control {
    padding: 0px 5px 0px 10px;
    height: 33px;
    min-height: 33px;
    width: 120px;
    @include h5;
    color: $white;
    @media screen and (max-width: $bookingFormBreakPoint1) {
      width: 82px;
      font-size: 12px;
      padding: 0px 3px 0px 5px;
    }
  }
  .customSelectV2__input-container {
    padding-left: 10px;
    @media screen and (max-width: $bookingFormBreakPoint1) {
      padding-left: 3px;
    }
  }
  .customSelectV2__menu {
    padding: 3px 3px 3px 5px;
    font-size: 12px;
  }

  & ~ .customSelectV2__errText {
    @include h5;
    font-size: 12px;
    font-weight: 400;
    color: $error;
    width: 220px;
    margin: 2px 0 0 7px;
  }

  &.time {
    .customSelectV2__title {
      width: 80px;
      font-size: 14px;
    }
    //  &.customSelectV2__wrapper {
    //     flex-wrap: wrap;
    //   }
    & ~ .customSelectV2__errText {
      @include h5;
      font-size: 12px;
      font-weight: 400;
      color: $error;
      width: 220px;
      margin: 2px 0 0 10px;
    }

    .customSelectV2__control {
      font-size: 14px;
    }
    .customSelectV2__menu {
      font-size: 14px;
      padding-left: 8px;
     
    }
    .customSelectV2__option{
      color:$text-secondary !important;
      &:hover {
        color: $color-8 !important;
      }
    }
  }
}
