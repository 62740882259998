@import "src/assets/mixins";

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
}

.currency {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }
}

.currencyTitle {
  padding: 8px;
  background-color: #f6faff;
  border-radius: $radius;

  @media (min-width: 761px) {
    background-color: $white;
  }
}

.settingsFields {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 1370px) {
    flex-direction: column;
  }

  @media (min-width: 761px) {
    margin-bottom: 30px;
    gap: 40px;
  }
}

.prices {
  display: grid;
  flex: 1;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 25px 20px;
  }

  @media (min-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.date {
  display: flex;
  gap: 10px;

  @media (max-width: 360px) {
    flex-direction: column;
  }
}

.dates {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 25px;
  }
}

.label {
  @include h4($text-secondary);
  margin-bottom: 10px;
}

.month {
  :global(.customSelectV2__control) {
    width: 130px;

    @media (min-width: 761px) {
      width: 200px;
    }
  }
}

.mainBtnsWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  @media (min-width: 481px) {
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}

.errorText {
  @include h5($text-error);
  margin: 5px 0 0 20px;
}

.certainList {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 20px;

  @media (min-width: 761px) {
    gap: 30px;
  }
}

.addPriceBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}
