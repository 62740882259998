@import "src/assets/mixins";
@import "src/assets/variables";
 
$cellHeigth:73px;
$mobileHeigth:50px;
$headMobileHeigth: 62px;

.container{
  position: relative;
  width: calc(100vw - 170px);
    
  @media screen and (max-width: $mainMobileBreakPoint){
    width: calc(100vw - 40px);
  }  
  @media screen and (max-width: 500px){
    width: calc(100vw - 15px);
  }  

.tableWrapper {
  
  max-height: calc(100vh - 255px);
  overflow-x: scroll;
  overflow-y: unset;
  scrollbar-color: $primary $color-26;
  scrollbar-width: thin; 
  &::-webkit-scrollbar-thumb{
    border-radius: 6px ; /* округлось бегунка */
    height: 50px;
  } 
  width: calc(100vw - 170px);
  @media screen and (max-width:1150px){
    max-height: calc(100vh - 230px);
  }
  @media screen and (max-width:$mainMobileBreakPoint){
    width: calc(100vw - 40px);
    max-height: calc(100vh - 160px);
  } 
  // @media screen and (max-width: 540px){
  //   max-height: calc(100vh - 155px);
  // } 
  @media screen and (max-width: 500px){
    width: calc(100vw - 15px);
  } 
  // @media screen and (max-width: 440px){
  //   max-height: calc(100vh - 300px);
  // } 
  // @media screen and (max-width: 324px){
  //   max-height: calc(100vh - 350px);
  // } 
}
}
.table{
  display: flex;
  justify-content: flex-start;  
  border: $border-table;
  position: relative;  
}
.column{
  display:grid;
  grid-template-rows:$cellHeigth; 

  @media screen and (max-width: $mainMobileBreakPoint){
    grid-template-rows: $mobileHeigth;    
  } 
  @media screen and (max-width: 600px){
    grid-template-rows:  $headMobileHeigth repeat(auto-fill, $mobileHeigth);    
  } 

  &:first-child{
    position: sticky;
    top:0px;
   
  }
  &.weekend{
    background-color: red;
  }
 
}
.aside{
  display: grid;
  grid-template-rows:$cellHeigth;  
  position: sticky;
  left:0;
  z-index: 6;
  @media screen and (max-width: $mainMobileBreakPoint){
    grid-template-rows:$mobileHeigth;   
  } 
  @media screen and (max-width: 600px ){
    grid-template-rows: $headMobileHeigth repeat(auto-fill, $mobileHeigth);   
  } 
  .object{
    border: $border-table;
    box-sizing: border-box;
    height: $cellHeigth;
    background-color: $color-26;
    justify-content: space-between;
    display: flex;
    align-items:center;
    padding: 20px;
    flex-direction: row;
    flex-grow: 0;
    gap: 5px;
    word-wrap: break-word;
    width: inherit;
    @media screen and (max-width: 1200px){
      padding: 10px;
    } 
    @media screen and (max-width: $mainMobileBreakPoint){
      font-size: 14px; 
      line-height: 16px;
      padding: 5px;    
      height: $mobileHeigth;      
    } 
    @media screen and (max-width: 600px){
      font-size: 11px;  
      font-weight: 500;     
      flex-direction: column;
      justify-content: center;
      max-width: 95px;
      line-height: 13px;
      padding-top: 1px;
      padding-bottom: 1px;
      gap:1px;
      span{
        height: 12px;
      }
    } 
  }
  .header{
    background-color: $color-13;
  }
  .obj_name{
    
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    @media screen and (max-width: $mainMobileBreakPoint){
      -webkit-line-clamp: 2;
      line-clamp: 2;
    } 
    @media screen and (max-width: 600px){
     text-align: center;
     word-wrap: break-word;
     width: inherit;
     overflow: hidden;
    } 
  }
}
.tableHeadNotation{
  z-index: 7;
  background-image: url('./../../assets/img/Rectangle.svg');
  background-color: $white;
  opacity: 1;
  background-size:auto auto;
  background-repeat: no-repeat;
  background-position: left -1  bottom -1;
  border: $border-table;
  word-wrap: break-word;
  padding: 0;
  width: 100%;
  @include h3;
  position: sticky;
  top: 0;
  .headRight {
    text-align: right;
    padding-right: 24px;
    padding-top: 12px;
  }
  .headLeft {
    padding-left: 18.5px;    
  }
  
  @media screen and (max-width: 1200px){
    background-image: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .headRight, .headLeft{
      flex: 50%;
      width: 100%;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
    }
    .headLeft{
      background-color: $color-26;
    }
  } 
  @media screen and (max-width: $mainMobileBreakPoint){ 
    // height:$headHeigth;
    .headRight, .headLeft{
      font-size: 16px;
    }
  }
  @media screen and (max-width: 600px){  
    .headRight, .headLeft{
      font-size: 12px;     
    }
  }
  
}
.notation {
  grid-row-start: 5;
  grid-row-end: 6;  
}
.headDate{
  text-align: center;
  @include h3mob;
  @media screen and (max-width: 1048px){
    font-size: 14px;
  }  
  @media screen and (max-width: 600px){
    display: none;   
  }  
  }
.dayOfWeek{
  text-align: center;
  @include h4;
  @media screen and (max-width: 600px){
    display: none;   
  }
}
.headDateMobile, .dayMobile{
  display: none;
  @media screen and (max-width: 600px){
    margin: 0px;
    display: block;
    font-size: 12px;
    line-height: 13.4px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 50%;
  } 
}
.headDateMobile{
  border-bottom: 1px solid $color-18;
  
}
.yearMobile{
  font-size: 10px;
  font-weight: 500;
  line-height: 12.2px;
  color: $color-4;
}
.weekMobile{
  font-weight: 400;
}

.tableGrid{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  height: $cellHeigth;
  box-sizing: border-box;
  border: $border-table;
  position: relative;
  @media screen and (max-width: $mainMobileBreakPoint){
    height: $mobileHeigth;
  }
  
  &.head{
    position: sticky;
    top:0px;
    z-index: 5;    
    @media screen and (max-width: 600px){
      justify-content: space-around;
      height: $headMobileHeigth;
    }    
  }
  &.weekendGrid{
    background-color: $color-36;
  }
}
.selectDate{
  .head{
    background-color: $color-8;
    .headDate, .dayOfWeek{   
      color:$white;
    }
    .headDateMobile{
      border-color: $color-4;
      color: $white;
    }
    .monthMobile, .yearMobile, .dateMobile, .weekMobile{
      color: $white;
    }
  }
}
.today, .today.day2{
  .head{
    background-color: $primary;
    .headDate, .dayOfWeek{
      color: $white;
    }
    .headDateMobile{
      border-color: $color-4;
    }
    .monthMobile, .yearMobile, .dateMobile, .weekMobile{
      color:$white;
    }
  }

}
.weekend{
  background-color: $color-30;
  .headDate, .dayOfWeek, .headDateMobile, .monthMobile, .yearMobile, .dateMobile, .weekMobile{
    color: $error;
  }
}
.arrow {
  cursor: pointer;
  transform: rotate(180deg);
  transform-origin: center;
  transition: all 0.1s;
  z-index: 0;
  &.open{
    transform: rotate(0deg)
  }
  @media screen and (max-width: 600px){
    svg{
      width: 15px;
      height: 12px;
    }
  } 
}
.modal{
  position: absolute;
  left: 50%;
  top:20vh;
  transform: translateY(-50%);
  transform: translateX(-50%);
  z-index: 20;
}  