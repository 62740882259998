@import "src/assets/mixins";

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .descr {
    @include h4($text-secondary);
    text-align: center;
  }

  &.bookingList {
    transform: translate(-50%, 50%);
    svg {
      width: 34px;
      height: 34px;
    }
    .descr {
      text-align: center;
      @include h3($text-secondary);
    }
  }
}
