@import "src/assets/mixins";
@import "src/assets/variables";
.container {
    @include b3;
    font-weight: 500;
    padding: 10px;
    display:flex;
    // grid-template-columns: 1fr 130px;
    justify-content: space-between;
    gap:20px;
    height: 71px;
    position:absolute;
    z-index: 2;  
    cursor: pointer;  
    &.tooltip{
      box-shadow: $shadow-2;
      z-index: 10;

    }
    @media screen and (max-width:1048px){
      gap:10px;
      padding: 5px;
    }
    @media screen and (max-width:$mainMobileBreakPoint){
      height: 48px;
      border-radius: $radius;
      padding: 1px 6px;
      align-items: center;
      font-size: 12px;      
      justify-content: space-around;
      gap:15px;
    }
    @media screen and (max-width:$mainMobileBreakPoint){
      gap:5px;
      justify-content: space-between;
    }
  }
  
  .last{
    background-color: $color-18;
    color: $color-4;
    &.tooltip{
      background-color: $color-18;
    } 
    .flag {
      opacity: 35%;
    }    
    .tt_icon {
      svg path{
        stroke:$color-4;
      }
    }    
    .rigth, .statusIcon{
      svg path{
        stroke:$color-4;        
      }
    }
      
  }
  .await{
    background-color: $color-19;
    color: $error; 
    .tt_icon {
      svg path{
        stroke:$error;
      }
    }  
    &.tooltip{
      background-color: $color-30;
    } 
    .rigth, .statusIcon{
      svg path{
        stroke:$error;
      }
    }
  }
  .full_paid{
    background-color: $color-20;
    color: $color-21;
    .tt_icon {
      svg path{
        stroke:$color-21;
      }
    }
    &.tooltip{
        background-color: $color-29;
    }
    .rigth, .statusIcon{
      svg path{
        stroke:$color-21;
      }
    }
  }
  .part_paid {
    background-color: $color-22;
    color:$color-23;
    .tt_icon {
      svg path{
        stroke:$color-23;
      }
    }
    &.tooltip{
        background-color: $color-28;
    }
    .rigth, .statusIcon{
      svg path{
        stroke:$color-23;
      }
    }
  }
  .not_paid {
    background-color: $color-24;
    color:$color-25;
    .tt_icon {
      svg path{
        stroke:$color-25;
      }
    }
    &.tooltip{
        background-color: $color-27;
    }
    .rigth, .statusIcon{
      svg path{
        stroke:$color-25;
      }
    }
  }
  
  
  
  
  .name {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    flex: 0 1 160px;
    gap:4px;    
    overflow: hidden;
    // justify-content: center;
    .last_name, .first_name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // max-width: 130px;
      margin-right: 5px;
    }
    
  }
  .flag{
    padding-top: 1px;
    @media screen and (max-width:600px){
      height: 15px;
    }
  }
  .left {    
    display: flex;
    justify-content: flex-start;
    gap: 14px;
    max-width: calc(100% - 95px);
    @media screen and (max-width:1048px){
      max-width: calc(100% - 115px);
    }
    @media screen and (max-width:$mainMobileBreakPoint){
      max-width: calc(100% - 124px);
    }

    @media screen and (max-width:600px){
      max-width: calc(100% - 30px);
      gap: 6px;
      // flex-direction: column;
      // gap:1px;
    }
  }  
  
  .rigth{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    @media screen and (max-width:1048px){
      font-size: 12px;
    }
    @media screen and (max-width:600px){
      display: none;
    }
  }
  .full_paid, .part_paid {
    .rigth{
     min-width: 85px;   
     @media screen and (max-width:$mainMobileBreakPoint){
      min-width: 110px; 
    }
    }
  }
  .statusIcon{
    // align-self: center;
    // margin-right: 10px;
    width: 16px;
    height: 16px;
    @media screen and (max-width:$mainMobileBreakPoint){
      width: 18px;
      height: 18px;
      svg{
        width: 16px;
        height: 16px;
      }
    }
  }
  .tt_icon{
    display: none;
    @media screen and (max-width:600px){
      display: block;
      height: 25px;
      svg{
        width: 25px;
        height: 25px;
        opacity: 50%;       
      }
    }
  }