@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 100%;
}
.header {
  // margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(4, 200px);
  justify-content: stretch;
  // height: 144px;
  height: 80px;
  align-items: center;  
  @include h2; 
  font-size: 24px;
  border: 1px solid $color-13;
  background-color: $color-7;
  overflow: hidden;
  border-radius: $radius;
  border-bottom: none;
  position: sticky;
  top:-10px;
  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr repeat(4, 144px);
    @include h2mob;
  }
  @media screen and (max-width: 1048px) {
    grid-template-columns: 1fr repeat(4, 130px);
    @include h3mob;
    button {
      height: 40px;
    }
  }
  @media screen and (max-width: $mainMobileBreakPoint) {
    grid-template-columns: repeat(4, 1fr);
    height: auto;
    background-color:$white;
    border: none;
    border-radius:0px;
    border-bottom: 2px solid $color-6;
    padding-bottom: 20px;
    top:44px;
    padding-top: 10px;
    @include b2($text-primary);
    button {
      height: 32px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 470px) {
    @include b3($text-primary);
    
    button {
      padding: 8px;
    }
  }
}
.column_head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-left: 1px solid $color-13;
  height: 100%;
  @media screen and (max-width: $mainMobileBreakPoint) {
    border: none;
  }  
}
.aside{
  text-align: center;
}
.featContainer{  
  .title{
    @include h2mob;
    padding: 20px 0px 10px 20px;
    @media screen and (max-width: $mainMobileBreakPoint) {
      text-align: center;
      @include b2($text-primary);
      font-weight: 600;
      padding-left: 0px;
    }
    
  }
}
.featWrap{
  border-left: 0.5px solid $color-13;
  border-right: 0.5px solid $color-13;
  border-top: 0.5px solid $color-13;
  border-radius: $radius;  
  @media screen and (max-width: $mainMobileBreakPoint) {
    border: none;
  }
}
.aside{
  @media screen and (max-width: $mainMobileBreakPoint) {
    display: none;
  }
}
.column_title {
  
  @media screen and (max-width: 400px) {
    max-width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

}